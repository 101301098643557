const React = require('react');
const PropTypes = require('prop-types');
const className = require('classnames');
const Markdown = require('../Markdown');

const TermsAndConditions = ({ text, customClass }) => (

  <div className={className("terms-and-conditions", { [customClass]: customClass })}><Markdown text={text} /></div>
);

TermsAndConditions.propTypes = {
  text: PropTypes.string,
};

TermsAndConditions.defaultProps = {
  text: '',
};

module.exports = React.memo(TermsAndConditions);
