const React = require('react');
const PropTypes = require('prop-types');
const { injectI18n } = require('nordic/i18n');

// Andes components
const { Modal } = require('@andes/modal');
const { Text, Typography } = require('@andes/typography');

// Utils
const translate = require('../../../../../translation');

// Constants
const {
  LINKS: { CONSUMER_CREDITS_TERMS_AND_CONDITIONS },
  COMMONS: { DEVICE_TYPE, MODAL_TYPE },
} = require('../../../../../../constants');
const { capitalizeFirstLetter } = require('../../../../../utils/format');

const GeneralTermsLink = ({ text, onClick }) => (
  <Typography size="xs" href="#" component="a" color="link" onClick={onClick}>
    {text}
  </Typography>
);

const ParticularConditionsLink = ({ text, onClick }) => (
  <Typography size="xs" href="#" component="a" color="link" onClick={onClick}>
    {text}
  </Typography>
);

const TermsAndConditions = ({ contractUrl, deviceType, i18n }) => {
  const {
    GENERAL_TERMS_LINK,
    PARTICULAR_CONDITIONS_LINK,
    GENERAL_TERMS_AND_CONDITIONS,
    YOU_WILL_BE_ABLE_TO_SEE_THIS_INFORMATION_AFTER_SELECTING_THE_NUMBER_OF_INSTALLMENTS,
  } = translate(i18n);

  const modalType = deviceType === DEVICE_TYPE.MOBILE ? MODAL_TYPE.FULL : MODAL_TYPE.SMALL;

  const [modal, setModal] = React.useState({ show: false, title: '' });
  const [iframe, setIframe] = React.useState({ url: '', title: '' });

  const handleModal = () => {
    setIframe({ url: '', title: '' });
    setModal({ show: false, title: '' });
  };

  const handleGeneralTermsClick = () => {
    setModal({
      show: true,
      title: capitalizeFirstLetter(GENERAL_TERMS_LINK()),
    });

    setIframe({
      url: CONSUMER_CREDITS_TERMS_AND_CONDITIONS,
      title: GENERAL_TERMS_LINK(),
    });
  };

  const handleParticularConditionsClick = () => {
    setModal({
      show: true,
      title: capitalizeFirstLetter(PARTICULAR_CONDITIONS_LINK),
    });

    setIframe({
      url: contractUrl,
      title: PARTICULAR_CONDITIONS_LINK,
    });
  };

  return (
    <>
      <Modal
        closable
        type={modalType}
        open={modal.show}
        title={modal.title}
        onClose={handleModal}
        className="review-express__consumer-credits__modal"
      >
        <div className="review-express__consumer-credits__modal__content">
          {contractUrl ? (
            <iframe width="100%" height="100%" title={iframe.title} src={iframe.url} />
          ) : (
            YOU_WILL_BE_ABLE_TO_SEE_THIS_INFORMATION_AFTER_SELECTING_THE_NUMBER_OF_INSTALLMENTS
          )}
        </div>
      </Modal>

      <Text size="xs" color="secondary">
        {GENERAL_TERMS_AND_CONDITIONS({
          components: {
            0: <GeneralTermsLink text={GENERAL_TERMS_LINK()} onClick={handleGeneralTermsClick} />,
            1: <ParticularConditionsLink text={PARTICULAR_CONDITIONS_LINK} onClick={handleParticularConditionsClick} />,
          },
        })}
      </Text>
    </>
  );
};

TermsAndConditions.propTypes = {
  deviceType: PropTypes.string.isRequired,
  contractUrl: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
};

module.exports = injectI18n(TermsAndConditions);
