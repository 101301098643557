const { DOCUMENTS } = require('../../constants/app');

const formatRemoveSpaces = (str, target = {}) => {
  const value = str?.replace(/\s/g, '');

  if (target?.value) {
    target.value = value;
  }

  return value;
};

const formatStringToNumber = (str, target = {}) => {
  const value = str?.match(/\d/g)?.join('') || '';

  if (target?.value) {
    target.value = value;
  }

  return value;
};

const formatString = (str, target = {}) => {
  const value = str?.match(/^[A-Za-zÁ-Úá-ú'\s]+/g)?.join('') || '';

  if (target?.value) {
    target.value = value;
  }

  return value;
};

const formatYapePhone = (phone, target = {}) => {
  const value = formatStringToNumber(phone)
    ?.replace(/^([0-9]{3})([0-9])/g, '$1 $2')
    ?.replace(/^([0-9]{3})\s([0-9]{3})([0-9])/g, '$1 $2 $3');

  if (target?.value) {
    target.value = value;
  }

  return value;
};

const formatDocument = (document, type, target = {}) => {
  let value = formatStringToNumber(document);

  switch (type) {
    case DOCUMENTS.CPF:
      value = value
        ?.replace(/(\d{3})(\d)/, '$1.$2')
        ?.replace(/(\d{3})(\d)/, '$1.$2')
        ?.replace(/(\d{3})(\d{1,2})/, '$1-$2')
        ?.replace(/(-\d{2})\d+?$/, '$1');
      break;

    case DOCUMENTS.CNPJ:
      value = value
        ?.replace(/(\d{2})(\d)/, '$1.$2')
        ?.replace(/(\d{3})(\d)/, '$1.$2')
        ?.replace(/(\d{3})(\d)/, '$1/$2')
        ?.replace(/(\d{4})(\d)/, '$1-$2')
        ?.replace(/(-\d{2})\d+?$/, '$1');
      break;

    default:
      return document;
  }

  if (target?.value) {
    target.value = value;
  }

  return value;
};

const formatZipcode = (zipcode, target = {}) => {
  const value = formatStringToNumber(zipcode)?.replace(/^(\d{5})(\d)/, '$1-$2');

  if (target?.value) {
    target.value = value;
  }

  return value;
};

// Intl.NumberFormat().format returns a non-breaking space (\xa0) between the currency symbol and the number
const intlNumberFormat = (locale, currency, amount) => {
  let format = Intl.NumberFormat(locale, { currency, style: 'currency' })
    .format(amount)
    .replace(/\$/g, '$ ')
    .replace(/\xa0/g, ' ')
    .replace(/\s+/g, ' ')
    .replace(/(\.|,)00$/g, '');

  if (locale === 'es-PE') {
    format = format.replace(/\./g, '#').replace(/,/g, '.').replace(/#/g, ',').replace(/S\//g, 'S/.');
  }

  return format;
};

const formatAmount = (siteId, locale, currency, amount) => {
  const sitesWithBankInterests = ['MLC', 'MPE', 'MCO'];
  const total = intlNumberFormat(locale, currency, amount);

  if (sitesWithBankInterests.includes(siteId)) {
    return `${total}*`;
  }

  return total;
};

// Intl.NumberFormat().format returns a non-breaking space (\xa0) between the currency symbol and the number
const formatAmountWithInstallments = (locale, currency, amount, installments) => {
  const total = intlNumberFormat(locale, currency, amount);

  if (installments === 1) {
    return total;
  }

  return `${installments}x ${total}`;
};

const capitalizeFirstLetter = (str) =>
  str && typeof str === 'string' ? `${str.charAt(0).toUpperCase()}${str.slice(1)}` : '';

module.exports = {
  formatAmount,
  formatString,
  formatZipcode,
  formatDocument,
  formatYapePhone,
  formatRemoveSpaces,
  formatStringToNumber,
  formatAmountWithInstallments,
  capitalizeFirstLetter,
};
