module.exports = {
  METHODS_HTTP: {
    GET: 'GET',
    HEAD: 'HEAD',
    OPTIONS: 'OPTIONS',
    PUT: 'PUT',
    POST: 'POST',
    DELETE: 'DELETE',
  },
  SITE_ID: {
    MLA: 'MLA',
    MLB: 'MLB',
    MLC: 'MLC',
    MLU: 'MLU',
    MLM: 'MLM',
    MPE: 'MPE',
    MCO: 'MCO',
    MLV: 'MLV',
    UNKNOWN: 'unknown-site',
  },
  LOCALE: {
    ES_AR: 'es-AR',
    ES_MX: 'es-MX',
  },
  CHECKOUT_TYPE: {
    REDIRECT: 'redirect',
    EMBED: 'embed',
    MODAL: 'modal',
  },
  CHECKOUT_CASE: {
    GUEST: 'guest',
    LOGGED: 'logged',
    PAYMENT: 'payment',
    EXPRESS: 'express',
    DEFAULT: 'default',
    SUBSCRIPTION: 'subscription',
  },
  DEVICE_TYPE: {
    DESKTOP: 'desktop',
    MOBILE: 'mobile',
    TABLET: 'tablet',
  },
  PAYMENT_METHOD: {
    ACCOUNT_MONEY: 'account_money',
    MERCADO_CREDITS: 'mercado_credits',
    DEBIT_CARD: 'debit_card',
    CREDIT_CARD: 'credit_card',
    pix: 'pix',
    SPEI: 'clabe',
  },
  ISSUER_ID: {
    VISA_MERCADO_PAGO: '12510',
  },
  EXPERIMENT: {
    YELLOW_BUTTON: 'yellow_button',
  },
  CHECKOUT_VERSION: {
    CHECKOUT: 'checkout',
    TOKENIZER: 'tokenizer',
  },
  ENVIROMENT: {
    PRODUCTION: 'production',
    DEVELOPMENT_MOCKS: 'development-mocks',
    DEVELOPMENT_FLOWS: 'development-flows',
    TEST: 'test',
    DEV: 'dev',
  },
  PLACEHOLDER: {
    CARD_NUMBER: '1234 1234 1234 1234',
    NIT: '999.999.999-9',
    CE: '9999999',
    CC: '9.999.999.999',
    INPUT_DOCUMENT_DEFAULT: '',
    CVV_THREE_DIGITS: '123',
    CVV_FOUR_DIGITS: '1234',
  },
  DATE_PLACEHOLDER: {
    MM: 'MM',
    YY: 'YY',
  },
  DELAY: {
    EIGHT_MILLISECONDS: 800,
    ONE_SECOND: 1000,
    TWO_SECONDS: 2000,
    THREE_SECONDS: 3000,
    FOUR_SECONDS: 4000,
    FIVE_SECONDS: 5000,
    SIX_SECONDS: 6000,
    SEVEN_SECONDS: 7000,
  },
  ANDES_PLACEMENT: {
    TOP: 'top',
    TOP_START: 'top-start',
    TOP_END: 'top-end',
    TOP_LEFT: 'topLeft',
    TOP_RIGHT: 'topRight',
    BOTTOM: 'bottom',
    BOTTOM_START: 'bottom-start',
    BOTTOM_END: 'bottom-end',
    BOTTOM_LEFT: 'bottomLeft',
    LEFT: 'left',
    LEFT_START: 'left-start',
    LEFT_END: 'left-end',
    RIGHT: 'right',
    RIGHT_START: 'right-start',
    RIGHT_END: 'right-end',
    AUTO: 'auto',
    CENTER: 'center',
  },
  BUTTON_HIERARCHY: {
    LINK: 'link',
    LOUD: 'loud',
    QUIET: 'quiet',
    TRANSPARENT: 'transparent',
  },
  MESSAGE_HIERARCHY: {
    LOUD: 'loud',
    QUIET: 'quiet',
  },
  INPUT_LENGTH: {
    ZIPCODE: 10,
    PHONE_NUMBER: 10,
    PERSONAL_FIRST_AND_LAST_NAME: 40,
    DEFAULT: 120,
  },
  INPUT_ID: {
    PHONE_NUMBER: 'phone_number',
    ZIPCODE: 'zip_code',
    PERSONAL_FIRST_NAME: 'personal_first_name',
    PERSONAL_LAST_NAME: 'personal_last_name',
    ENTITY_TYPE_JURIDIC: 'Jurídica',
  },
  REGEX: {
    ONLY_NUMERIC: /[^\d+]/g,
    ONLY_LETTERS: /[^a-zA-Z\s]/g,
  },
  PAYMENT_METHOD_TYPE: {
    ACCOUNT_MONEY: 'account_money',
    DIGITAL_CURRENCY: 'digital_currency',
    CREDIT_CARD: 'credit_card',
    DEBIT_CARD: 'debit_card',
    PREPAID_CARD: 'prepaid_card',
    TICKET: 'ticket',
    BANK_TRANSFER: 'bank_transfer',
    SPLIT: 'split',
    OFFLINE: 'Offline',
  },
  PAYMENT_METHOD_ID: {
    ACCOUNT_MONEY: 'account_money',
    CONSUMER_CREDITS: 'consumer_credits',
  },
  PAYMENT_STATUS: {
    APPROVED: 'approved',
    PENDING: 'pending',
  },
  TOOLTIP_TYPE: {
    DARK: 'dark',
    LIGHT: 'light',
  },
  ELEMENT_TYPE: {
    TEXT: 'text',
    PRICE: 'price',
    ROW_SUMMARY: 'row_summary',
    INFORMATION: 'information',
  },
  THEME: {
    DEFAULT: 'default',
    LIGHT: 'light',
    DARK: 'dark',
    YELLOW_BUTTON: 'yellow_button',
  },
  COLORS_BY_STATUS: {
    SUCCESS: 'green',
    WARNING: 'orange',
    ERROR: 'red',
    INFO: 'accent',
  },
  INPUT_MODE: {
    DECIMAL: 'decimal',
  },
  EXTERNAL_URLS: {
    LEGAL_TERMS: 'https://www.mercadopago.com.ar/ayuda/terminos-y-politicas_194',
    CAPTCHA_PRIVACY: 'https://policies.google.com/privacy',
    CAPTCHA_TERMS: 'https://policies.google.com/terms',
    PROTECT_YOUR_PURCHASE: 'https://www.mercadopago.com.co/ayuda/proteger-compras_4233',
  },
  ASSETS: {
    MERCADOPAGO_LOGO: 'https://http2.mlstatic.com/frontend-assets/px-checkout-frontend/70862a347a0e3da737ed.png',
    KYC_IMAGE: 'https://http2.mlstatic.com/frontend-assets/px-checkout-frontend/68f9cb57bb3e32965e05.svg',
  },
  URL_HASH: {
    TYC_CC_PARTICULAR: 'consumer_credits_tyc_particular',
    TYC_CC_CONTRACT: 'consumer_credits_contract',
    TYC_CC: 'consumer_credits_tyc',
  },
  CONDITIONS_TYPES: {
    GENERAL: 'general',
    PARTICULAR: 'particular',
    CONTRACT: 'contract',
  },
  MODAL_TYPE: {
    FULL: 'full',
    CARD: 'card',
    SMALL: 'small',
    LARGE: 'large',
  },
  ANDES_MESSAGES: {
    neutral: 'accent',
    warning: 'orange',
  },
  ANDES_MONEY_DECIMAL_SEPARATOR: {
    DOT: 'dot',
    COMMA: 'comma',
  }
};
