const { handleActions } = require('redux-actions');

const { DUPLICATED_PAYMENT_MODAL } = require('../actions/types');

const initialState = {
  show: false,
  allowDuplicatedPayment: false,
  paidWithALoggedUser: false,
  firstAppearance: null,
};

module.exports = handleActions(
  {
    [DUPLICATED_PAYMENT_MODAL.SHOW]: (state, action) => ({
      ...state,
      paidWithALoggedUser: action.payload.paidWithALoggedUser,
      show: true,
      firstAppearance: state.firstAppearance || new Date(),
    }),

    [DUPLICATED_PAYMENT_MODAL.CLOSE]: (state) => ({
      ...state,
      show: false,
    }),

    [DUPLICATED_PAYMENT_MODAL.ALLOW_DUPLICATED_PAYMENT]: (state) => ({
      ...state,
      show: false,
      allowDuplicatedPayment: true,
    }),
  },
  initialState,
);
