module.exports = {
  INPUTS_ID: {
    DISCOUNT_CODE: 'user-discount-code-input',
    USER_EMAIL: 'user-email-input',
  },
  BUTTONS: {
    MOBILE_PAY: 'cow-sticky__pay',
    DESKTOP_PAY: 'cow-payment_summary__button',
    DISCOUNT_TRIGGER: 'discount-btn--trigger',
  }
};
