module.exports = {
  REVIEW_EXPRESS: {
    NAME: 'reviewexpress',
    ACTION: {
      PAY: 'pay',
      LOADED: 'loaded',
      CHANGED: 'changed',
    },
  },
};
