/* global document */
const React = require('react');
const PropTypes = require('prop-types');

const injectI18n = require('nordic/i18n/injectI18n');
const { Head } = require('nordic/head');
const { connect } = require('react-redux');
const { Snackbar } = require('@andes/snackbar');
const { bindActionCreators } = require('redux');

const PageLoader = require('../PageLoader');
const { closeModal } = require('../../utils/terminateFlow');
const { trackMelidata } = require('../../utils/Tracker');
const melidataTracking = require('../../utils/melidataTracking');
const RenderJS = require('../../utils/RenderJs');
const IconCross = require('../icons/Cross');
const FullscreenMessage = require('../FullscreenMessage');
const AdTracking = require('../AdTracking');
const trackingActions = require('../../spa/actions/tracking');
const { TRACKING_LOADED, EXECUTE_TRACKING } = require('../../spa/actions/types');
const SandboxLabel = require('../SandboxLabel');
const SiteSecurityWidget = require('../SiteSecurityWidget');
const CheckCookie = require('../CheckCookie');
const MercadoPagoSDK = require('../MercadoPagoSDK');
const { setDomTitle } = require('../../utils/Dom');
const { SPA_NR_ACTIONS } = require('../../utils/newRelic');
const { reduceDatesDifferenceToMinutes } = require('../../utils/Date');
const translate = require('../../translation');
const { CURRENT_STEP } = require('../../../constants/app');
const { ENVIROMENT } = require('../../../constants/commons');

class Page extends React.Component {
  constructor(props) {
    super(props);
    const { i18n } = props;
    this.state = {
      showCloseMessage: false,
      loadSDK: false,
    };
    this.confirmClose = this.confirmClose.bind(this);
    this.dismissClose = this.dismissClose.bind(this);
    this.handlePageLoad = this.handlePageLoad.bind(this);
    this.shouldShowCloseButton = this.shouldShowCloseButton.bind(this);
    this.shouldShowCloseButtonTitle = this.shouldShowCloseButtonTitle.bind(this);
    this.confirmCloseRedirect = this.confirmCloseRedirect.bind(this);
    this.closeDirectly = this.closeDirectly.bind(this);
    this.translations = translate(i18n);
  }

  /**
   * Adds functionality to the close modal button in the Layout, needs to use global js
   * Sends request to middle-end for it to end the flow
   * Sends message to parent frame to close modal
   */
  componentDidMount() {
    // Set/Update the page title
    if (document?.title !== this.props.title) {
      setDomTitle(this.props.title);
    }
    this.handlePageLoad();
    this.updatePageLoadedMetric();
    this.shouldLoadSDK();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.trackData &&
      this.props.trackData.category &&
      this.props.trackData.label &&
      this.props.trackData.action
    ) {
      this.props.trackingActions[EXECUTE_TRACKING](null);
    }

    if (this.props.currentStep !== prevProps.currentStep) {
      this.handlePageLoad();
    }
  }

  handlePageLoad() {
    const { currentStep, flow } = this.props;

    const closeButtons = document.querySelectorAll('[data-close-payment]'); // eslint-disable-line

    // If the close button exists add the functionality
    if (closeButtons.length > 0) {
      for (let i = 0; i < closeButtons.length; i++) {
        const closeButton = closeButtons[i];

        let closeButtonAction;
        // Close button action may change depending on the current step, by default will display close message
        switch (flow.step) {
          case CURRENT_STEP.CONGRATS_REDIRECT:
            closeButtonAction = this.confirmCloseRedirect;
            break;
          case CURRENT_STEP.BLOCKED_POPUPS:
            closeButtonAction = this.props.onCheckoutClose;
            break;
          case CURRENT_STEP.RESTART_FLOW:
            closeButtonAction = this.closeDirectly;
            break;
          default:
            closeButtonAction = () => this.setState({ showCloseMessage: true });
        }
        closeButton.addEventListener('click', () => {
          // eslint-disable-line
          closeButtonAction();
        });
      }
    }

    let experimentClass = '';
    const melidataTrackingData = melidataTracking.getData(this.props.hidden_components);

    if (this.props.firstRender && this.props.flow.type === 'modal') {
      // Backwards compatibility
      RenderJS.start(this.props.pageData.hidden_components);
    } else if (!this.props.firstRender && this.props.track) {
      // track melidata
      if (
        this.props.trackPageToMelidata &&
        melidataTrackingData &&
        melidataTrackingData.path &&
        melidataTrackingData.payload
      ) {
        trackMelidata(melidataTrackingData, this.props);
      }
      // trigger action to indicate tracking was fired
      this.props.trackingActions[TRACKING_LOADED](true);
    }

    // Update Experiment Class
    if (melidataTrackingData && melidataTrackingData.experiments) {
      const experimentsTrack = [];
      const { experiments } = melidataTrackingData;

      Object.keys(experiments).forEach((key) => {
        const exp = experiments[key];
        experimentsTrack.push(`experiment--${exp.id}`);
      });

      if (experimentsTrack.length > 0) {
        experimentClass = `experiment ${experimentsTrack.join(' ')}`;
      }
    }
    if (currentStep) {
      const bodyClass = document.body.className
        .split(' ')
        .filter((e) => !e.startsWith('step_') && !e.startsWith('experiment'))
        .filter(Boolean);
      if (experimentClass) {
        bodyClass.push(experimentClass);
      }
      document.body.className = `${bodyClass.join(' ')} step_${currentStep}`;
    }
  }

  updatePageLoadedMetric(initialState = window.__PRELOADED_STATE__) {
    if (!this.props.firstRender && initialState?.startTimeServer) {
      // ONLY called when react mounts an actual page (skip skeleton)
      const loadedTime = reduceDatesDifferenceToMinutes(initialState?.startTimeServer, Date.now());
      const lessThanTwoMinutes = 120000; // this will prevent to log the metric where users have a custom set time set
      loadedTime < lessThanTwoMinutes &&
        window.newrelic?.addPageAction(SPA_NR_ACTIONS.RENDER_TIME, { renderTime: loadedTime });
    }
  }

  shouldLoadSDK(initialState = window.__PRELOADED_STATE__) {
    if (!(initialState && initialState.startTimeServer) || (initialState?.startTimeServer && !this.props.firstRender)) {
      // only will load after flow id is created it.
      if (!this.state.loadSDK) {
        this.setState({
          loadSDK: true,
        });
      }
    }
  }

  hideCookieDisclaimerBanner() {
    const cookieDisclaimerElement = document.getElementById('newCookieDisclaimerBanner');

    if (cookieDisclaimerElement) {
      cookieDisclaimerElement.style.visibility = 'hidden';
    }
  }

  confirmClose() {
    // Call an onCheckoutClose method
    if (this.props.onCheckoutClose) {
      this.props.onCheckoutClose();
    }

    closeModal(this.props.pageData.hidden_components);

    this.hideCookieDisclaimerBanner();
  }

  closeDirectly() {
    this.dismissClose();
    closeModal(this.props.pageData.hidden_components);
    this.hideCookieDisclaimerBanner();

    this.props.externalRedirectWindow?.close();
  }

  dismissClose() {
    this.setState({
      showCloseMessage: false,
    });
  }

  shouldShowCloseButton(step) {
    return (
      step !== 'processing' &&
      step !== 'congrats_approved' &&
      step !== 'congrats_instructions' &&
      step !== 'congrats_review_manual' &&
      step !== 'congrats_remedy_offer' &&
      step !== 'congrats_contingency'
    );
  }

  shouldShowCloseButtonTitle(step) {
    return step !== 'congrats_redirect' && step !== 'blocked_popups';
  }

  shouldShowLoader(step, showLoader) {
    switch (step) {
      case 'opensea_credits_first_use':
      case 'webpay_one_click':
      case 'sniffing_scan_qr':
        return false;
      default:
        return showLoader;
    }
  }

  confirmCloseRedirect() {
    if (this.props.dynamicDescription) {
      this.props.onCheckoutClose();
    } else {
      this.setState({
        showCloseMessage: true,
      });
    }
  }

  render() {
    const { children, showLoader, deviceType, flow, snackbar, title, checkForCookie } = this.props;

    return (
      <>
        {checkForCookie && <CheckCookie />}
        <AdTracking components={this.props.hidden_components} />
        <Head>{title && <title>{title}</title>}</Head>
        {this.state.loadSDK && <MercadoPagoSDK />}
        {/** add sandbox label and class if using test credentials  */}
        <SandboxLabel testCredentials={this.props.testCredentials} text={this.translations.HOW_TO_USE_IT} />
        {deviceType === 'desktop' && this.shouldShowCloseButton(flow.step) && (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <button type="button" id="mp-close-btn" className="layout-close-btn u-no-user-select" data-close-payment>
            <IconCross title={this.shouldShowCloseButtonTitle(flow.step) ? this.translations.CANCEL_PAYMENT : ''} />
          </button>
        )}
        <div className="page-mask">
          {children}
          {snackbar.show && (
            <Snackbar message={snackbar.message} color={snackbar.status} show={snackbar.show} delay={snackbar.delay} />
          )}
          {this.shouldShowLoader(flow.step, showLoader) && <PageLoader forceLoading={this.props.forceLoading} />}

          {this.state.showCloseMessage && (
            <FullscreenMessage
              title={this.translations.ONE_MOMENT}
              message={this.translations.IF_YOU_CLOSE_THIS_WINDOW_YOU_WILL_LOSE_ALL_THE_DATA_YOU_LOADED}
              mainAction={{
                label: this.translations.CONTINUE_PAYMENT,
                onClick: this.dismissClose,
              }}
              secondaryAction={{
                label: this.translations.CLOSE_AND_CANCEL_PAYMENT,
                onClick: this.confirmClose,
              }}
            />
          )}
        </div>
        {/** add fp widget with session id */}
        <SiteSecurityWidget
          siteSecurity={this.props.siteSecurity}
          cspNonce={this.props.cspNonce}
          version={this.props.version}
        />
      </>
    );
  }
}

Page.defaultProps = {
  title: '',
  cspNonce: '',
  siteSecurity: {},
  currentStep: '',
  checkoutType: '',
  siteId: '',
  showLoader: true,
  firstRender: true,
  analytics: {},
  pageData: {},
  track: true,
  trackData: null,
  trackingPath: '/UNKNOWN',
  children: null,
  isWebview: false,
  version: '',
  i18n: {
    gettext: (t) => t,
    pgettext: (t) => t,
  },
  flow: {
    id: '',
    type: '',
    step: '',
    action: '',
  },
  deviceType: 'desktop',
  forceLoading: false,
  internalConfigurations: [],
  onCheckoutClose: null,
  hidden_components: [{}],
  snackbar: {
    show: false,
    status: '',
    message: '',
  },
  checkForCookie: true,
  trackingActions: {},
  testCredentials: false,
  trackPageToMelidata: true,
};

Page.propTypes = {
  title: PropTypes.string,
  cspNonce: PropTypes.string,
  siteSecurity: PropTypes.shape({
    session_id: PropTypes.string,
    widget: PropTypes.string,
  }),
  currentStep: PropTypes.string,
  siteId: PropTypes.string,
  checkoutType: PropTypes.string,
  showLoader: PropTypes.bool,
  firstRender: PropTypes.bool,
  isWebview: PropTypes.bool,
  internalConfigurations: PropTypes.arrayOf(PropTypes.any),
  analytics: PropTypes.shape({
    dimensions: PropTypes.object,
    platform: PropTypes.string,
    section: PropTypes.string,
    config: PropTypes.shape({
      customs: PropTypes.object,
      dimensions: PropTypes.object,
    }),
  }),
  pageData: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  track: PropTypes.bool,
  trackData: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  trackingPath: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  version: PropTypes.string,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  flow: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    step: PropTypes.string,
    action: PropTypes.string,
  }),
  deviceType: PropTypes.string,
  forceLoading: PropTypes.bool,
  // This prop is use to execute a call to the merchant on modal / redirect (from widget)
  onCheckoutClose: PropTypes.func,
  hidden_components: PropTypes.arrayOf(PropTypes.shape()),
  snackbar: PropTypes.shape({
    show: PropTypes.bool,
    status: PropTypes.string,
    message: PropTypes.string,
  }),
  checkForCookie: PropTypes.bool,
  trackingActions: PropTypes.object,
  testCredentials: PropTypes.bool,
  externalRedirectWindow: PropTypes.shape({
    close: PropTypes.func,
  }),
  trackPageToMelidata: PropTypes.bool,
};

/**
 * Generate the state (store) using the reducers
 * @param state
 */
const mapStateToProps = (state) => ({
  cspNonce: state.configurations.cspNonce,
  internalConfigurations: state.configurations.internalConfigurations,
  cookieDisclaimerConfig: state.configurations.cookieDisclaimer,
  platform: state.configurations.platform,
  browser: state.configurations.browser,
  siteSecurity: state.configurations.siteSecurity,
  testCredentials: state.configurations.testCredentials,
  pageData: state.page.data,
  siteId: state.configurations.platform.siteId,
  isWebview: state.configurations.isWebview,
  checkoutType: state.configurations.checkoutType,
  version: state.configurations.version,
  checkoutPurpose: state.configurations.checkoutPurpose,
  checkoutProduct: state.configurations.checkoutProduct,
  firstRender: state.page.firstRender, // If is not the first render track analytics must be enabled
  flow: state.page.flow,
  hidden_components: state.page.data.hidden_components,
  snackbar: state.snackbar,
  dynamicDescription: state.transitionRedirect.dynamicDescription,
  trackData: state.tracking.trackData,
  externalRedirectWindow: state.externalRedirectWindow.window,
});

const mapDispatchToProps = (dispatch) => ({
  trackingActions: bindActionCreators(trackingActions, dispatch),
});

if (process.env.NODE_ENV === ENVIROMENT.TEST) {
  module.exports = Page;
} else {
  /* istanbul ignore next: cant test it with tests */
  module.exports = connect(mapStateToProps, mapDispatchToProps)(injectI18n(Page));
}
