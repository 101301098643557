/* eslint-env browser */
/**
 * Polyfills
 */
require('./polyfills');

/**
 * Module dependencies
 */

const React = require('react');

const { hydrate } = require('nordic/hydrate');
const { Provider } = require('react-redux');
const I18n = require('nordic/i18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const { preloadReady, SPAContainer } = require('frontend-spa');

const routes = require('../spa/routes');
const { createStore } = require('../spa/store');
const AppContainer = require('../spa/components/AppContainer');
const ErrorBoundary = require('../components/ErrorBoundary');
const Page = require('../components/Page'); // Hack for making the page part of the bundle to make the flow request faster.
const DuplicatedPaymentModal = require('../components/DuplicatedPaymentModal');

/**
 * Get server state
 */
const state = window.__PRELOADED_STATE__;
const { translations } = state;

/**
 * i18ns
 */
const i18n = new I18n({ translations });

preloadReady().then(() => {
  // Preload the store with the data from props (coming from SSR preloaded store)
  const store = createStore(state.initialStore);

  () => Page;
  hydrate(
    <Provider store={store}>
      <I18nProvider i18n={i18n}>
        <ErrorBoundary>
          <SPAContainer {...state} routes={routes} Container={AppContainer} basePath={state.baseName} />
          {/* TECH DEBT: search a new place to put this component */}
          <DuplicatedPaymentModal />
        </ErrorBoundary>
      </I18nProvider>
    </Provider>,
    document.getElementById('root-app'),
  );
});
