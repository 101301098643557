const React = require('react');

const { connect } = require('react-redux');
const { injectI18n } = require('nordic/i18n');

const {
  COMMONS: { ENVIROMENT },
} = require('../../constants');

const withI18nMock = (Component) => (props) =>
  React.createElement(Component, {
    ...props,
    i18n: {
      gettext: (string) => string,
    },
  });

const connectToReduxAndInjectI18n = (component, mapStateToProps, mapDispatchToProps) =>
  process.env.NODE_ENV === ENVIROMENT.TEST
    ? withI18nMock(component)
    : connect(mapStateToProps, mapDispatchToProps)(injectI18n(component));

module.exports = connectToReduxAndInjectI18n;
