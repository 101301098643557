/**
 * Modules dependencies
 */
const https = require('https');
const querystring = require('querystring');

const NordicRestClient = require('nordic/restclient');

const { REAUTH } = require('../../constants/app');
const Headers = require('../utils/Headers');
const { VALID_HEADERS } = require('../utils/Headers');

/**
 * the client TO should never be lower than the service TO to avoid to abandon the server request
 * before the server does it
 */
const FLOWS_CLIENT_GET_TIMEOUT = 15000;
const FLOWS_CLIENT_PUT_TIMEOUT = 65000;

/**
 * This client is browser ONLY (using the internal API). This file is going to be the restclient.browser.js
 */
const rcConfig = require('../../config/restclient')({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
});

const restclient = NordicRestClient(rcConfig);

/**
 * Service interface
 */
class ApiService {
  /**
   * Get Flow
   * @param version (tokenizer, checkout)
   * @param flowId
   * @param type (embed, modal, redirect)
   * @param step to get
   * @param action
   * @param urlParams
   * @param options
   * @returns {Promise<T>}
   */
  static async getFlow(version, flowId, type, step, action, urlParams, options = {}) {
    const requestOptions = {
      params: {
        action,
        type,
        urlParams,
        step,
        version,
      },
      timeout: FLOWS_CLIENT_GET_TIMEOUT,
    };

    if (options.cancelToken) {
      requestOptions.cancelToken = options.cancelToken;
    }

    return restclient
      .get(`/flow/${flowId}`, requestOptions)
      .then(({ data }) => data)
      .catch(async (err) => Promise.reject(err));
  }

  /**
   * Update Flow
   * @param version (tokenizer, checkout)
   * @param flowId
   * @param putData
   * @param options
   * @returns {Promise<T>}
   */
  static async updateFlow(
    version,
    flowId,
    putData = {},
    options = {},
    currentStep = null,
    urlParams = null,
    isReauthChallenge = false,
  ) {
    const requestOptions = {};
    const queryObj = querystring.parse(urlParams); // get the queryParams as object

    let accessTokenParam = '';

    if (options.cancelToken) {
      requestOptions.cancelToken = options.cancelToken;
    }

    /**
     * @TODO migrate all these to headers util
     */
    const headers = {};

    if (options.idempotencyKey) {
      headers['X-Idempotency-Key'] = options.idempotencyKey;
    }

    if (isReauthChallenge) {
      headers[VALID_HEADERS.X_VERIFY_REAUTH.name] = true;
    }

    // Add the current version
    putData.version = version;

    if (currentStep) {
      putData.prev_step = currentStep;
    }

    /**
     * pass the access token as a query param to the ajax calls to get the user session
     */
    if (urlParams && queryObj && queryObj.accessToken) {
      accessTokenParam = querystring.stringify({ accessToken: queryObj.accessToken });
    }

    if (options.logout) {
      putData.logout = true;
    }

    return restclient
      .put(`/flow/${flowId}?${accessTokenParam}`, {
        data: putData,
        headers,
        ...requestOptions,
        timeout: FLOWS_CLIENT_PUT_TIMEOUT,
      })
      .then(({ data }) => {
        if (data?.error) {
          throw Error(data.error?.status);
        }

        return data;
      })
      .catch(async (err) => Promise.reject(err));
  }

  /**
   * Retry Flow
   * @param version (tokenizer, checkout)
   * @param flowId
   * @param type
   * @param urlParams
   * @returns {Promise<T>}
   */
  static async retryFlow(version, flowId, type, urlParams) {
    const requestOptions = {
      params: {
        type,
        urlParams,
        version,
        timeout: FLOWS_CLIENT_GET_TIMEOUT,
      },
    };

    return restclient
      .get(`/flow/${flowId}/current`, requestOptions)
      .then(({ data }) => data)
      .catch(async (err) => Promise.reject(err));
  }

  /**
   * Check if user is logged
   * @returns {Promise<T>}
   */
  static async isUserLogged() {
    return restclient
      .get('user')
      .then(({ data }) => data)
      .catch(async (err) => Promise.reject(err));
  }

  /**
   * Get Agencies
   * @param siteId
   * @param data
   * @param options
   * @returns {Promise<T | Array>}
   */
  static async getAgencies(siteId, data = {}, options = {}) {
    const requestOptions = {};
    const { paymentMethodId, lat, lng, radius, limit, offset } = data;

    // If there is a cancelToken set it on the request
    if (options.cancelToken) {
      requestOptions.cancelToken = options.cancelToken;
    }

    const queryParams = querystring.stringify({
      siteId,
      payment_method: paymentMethodId,
      lat,
      lng,
      limit,
      offset,
      radius,
    });

    return restclient
      .get(`/agencies/search?${queryParams}`, {
        ...requestOptions,
      })
      .then(async (response) => Promise.resolve(response.data.results))
      .catch(async () => Promise.resolve([]));
  }

  static createFlowFromAction(checkout, urlParams) {
    const isProduction = process.env.NODE_ENV === 'production';

    return restclient
      .post(`/flow?${urlParams}`, {
        timeout: isProduction ? 6000 : 30000,
        data: checkout,
      })
      .then(async (response) => Promise.resolve(response.data))
      .catch(async (err) => Promise.resolve(err));
  }

  static getCities(stateId) {
    return restclient
      .get(`/countries/${stateId}/cities`)
      .then(async (response) => Promise.resolve(response.data.cities))
      .catch(async (err) => Promise.reject(err));
  }

  static getSuggestedZipcode(stateName, cityName, streetName, streetNumber, neighborhood) {
    const queryParams = querystring.stringify({
      stateName,
      cityName,
      streetName,
      streetNumber,
      neighborhood,
    });

    return restclient
      .get(`/countries/suggest/zipcode?${queryParams}`)
      .then(async (response) => Promise.resolve(response.data.suggestedZipCode))
      .catch(async (err) => Promise.reject(err));
  }

  /**
   * Track error in the API
   * @param error
   * @returns {*}
   */
  static logErrorKibana(errorMessage, tags = {}) {
    return restclient.post('/error', {
      data: { errorMessage, tags },
    });
  }

  static trackInfo(error) {
    const qs = window.location && window.location.search ? window.location.search.substring(1) : '';
    const queryObj = querystring.parse(qs);
    const query = queryObj['preference-id'] ? `?preference-id=${queryObj['preference-id']}` : '';

    return restclient.post(`/info${query}`, {
      data: error,
    });
  }

  static getKycStatus(initiativeId) {
    return restclient.get(`/kyc/status/${initiativeId}`, {});
  }

  /**
   * ChallengeConfig function that builds login challenge configuration
   * @param callbackUrl - desired url to use a return url from challenge
   * @param {Object} payment - info about payment
   */
  static checkReauthentication(callbackUrl, flowId, meliSessionId) {
    const redirectUrl = encodeURIComponent(callbackUrl);

    const headers = {
      [VALID_HEADERS.X_REQUEST_WITH.name]: VALID_HEADERS.X_REQUEST_WITH.value.XMLHttpRequest,
      [VALID_HEADERS.X_MELI_SESSION_ID.name]: meliSessionId,
    };

    const formatReauthResponse = ({ status, data }) => {
      if (status === REAUTH.ERROR.UNAUTHORIZED) {
        return { reauthUrl: data?.url };
      }

      return { reauthUrl: null };
    };

    return restclient
      .get(`/reauth?redirectUrl=${redirectUrl}&flowId=${flowId}`, {
        headers,
      })
      .then((response) => formatReauthResponse(response))
      .catch(({ response }) => formatReauthResponse(response));
  }

  /**
   * Get Card Issuer
   * @param {string} bin
   * @param {string} siteId
   * @returns {Promise<Object>}
   */
  static async getCardIssuer(bin, siteId) {
    const queryParams = querystring.stringify({ bin, siteId });

    return restclient
      .get(`/bin/search?${queryParams}`)
      .then(async (response) => Promise.resolve(response.data))
      .catch(async (error) => Promise.reject(error));
  }

  /**
   * Get location information by zipcode
   * @param {string} zipcode
   * @returns {Promise<{object}>}
   */
  static async getLocations(zipcode) {
    return restclient
      .get(`/zipcode/${zipcode}/locations`)
      .then(async (response) => Promise.resolve({ locations: response.data }))
      .catch(async (err) => Promise.reject(err));
  }

  /**
   * Get relation status by id
   * @param {string} id
   * @returns {Promise<{object}>}
   */
  static async getRelationById(id) {
    return restclient
      .get(`/relation/${id}`)
      .then(async ({ data }) => Promise.resolve({ data }))
      .catch(async (err) => Promise.reject(err));
  }

  /**
   * Get payment status by paymentId
   * @param {string} paymentId
   * @param {boolean} isTest
   * @returns {Promise<Object>}
   */
  static async getPaymentStatus(paymentId, isTest) {
    const headers = new Headers().addApiTest(isTest);

    return restclient
      .get(`/openFinance/${paymentId}`, { headers: headers.get() })
      .then(async ({ data }) => Promise.resolve({ data }))
      .catch(async (err) => Promise.reject(err));
  }

  /**
   * Get installments
   * @param {string} queryParams
   * @returns {Promise<Object>}
   */
  static async getInstallments(queryParams) {
    return restclient
      .get(`/installments?${queryParams}`)
      .then(async (response) => Promise.resolve(response.data))
      .catch(async (error) => Promise.reject(error));
  }

  /**
   * Get getLogoUrlById
   * @param {string} queryParams
   * @returns {Promise<Object>}
   */
  static async getLogoUrlById(id) {
    return restclient
      .get(`/logos/${id}`)
      .then(async (response) => Promise.resolve(response.data.images[0].url))
      .catch(async (error) => Promise.reject(error));
  }

  /**
   * Send metrics to datadog
   * @param {*} data
   * @returns {Promise<void>}
   */
  static async sendDatadogMetric(data) {
    try {
      await restclient.post('/metrics', { data });
    } catch (error) {
      // empty catch to leave the request as a non-blocking thread
    }
  }
}

/**
 * Expose Service
 */
module.exports = ApiService;
