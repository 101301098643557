const { sendDatadogMetric } = require('./api');
const { REVIEW_EXPRESS } = require('../../constants/metrics');

const sendReviewExpressMetric = ({ flow, action, preference, paymentMethod, device }) => {
  const tags = [
    `flow:${flow}`,
    `action:${action}`,
    `device:${device}`,
    `preference:${preference}`,
    `paymentmethod:${paymentMethod}`,
  ];

  sendDatadogMetric({ tags, name: REVIEW_EXPRESS.NAME });
};

const sendReviewExpressLoadMetric = ({ preference, flow, paymentMethods, device }) => {
  sendReviewExpressMetric({
    flow,
    device,
    preference,
    action: REVIEW_EXPRESS.ACTION.LOADED,
    paymentMethod: paymentMethods?.map((pm) => pm?.type)?.join(' '),
  });
};

const sendReviewExpressPayMetric = ({ preference, flow, paymentMethod, device }) => {
  sendReviewExpressMetric({
    flow,
    device,
    preference,
    paymentMethod,
    action: REVIEW_EXPRESS.ACTION.PAY,
  });
};

const sendReviewExpressChangedMetric = ({ preference, flow, paymentMethod, device }) => {
  sendReviewExpressMetric({
    flow,
    device,
    preference,
    paymentMethod,
    action: REVIEW_EXPRESS.ACTION.CHANGED,
  });
};

module.exports = {
  sendReviewExpressPayMetric,
  sendReviewExpressLoadMetric,
  sendReviewExpressChangedMetric,
};
