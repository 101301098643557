const localeFromSiteId = (siteId) => {
  const locale = {
    locale: 'es-AR',
    currency: 'ARS',
  };

  if (siteId) {
    switch (siteId.toUpperCase()) {
      case 'MLB':
        locale.locale = 'pt-BR';
        locale.currency = 'BRL';
        break;

      case 'MLC':
        locale.locale = 'es-CL';
        locale.currency = 'CLP';
        break;

      case 'MCO':
        locale.locale = 'es-CO';
        locale.currency = 'COP';
        break;

      case 'MLM':
        locale.locale = 'es-MX';
        locale.currency = 'MXN';
        break;

      case 'MPE':
        locale.locale = 'es-PE';
        locale.currency = 'PEN';
        break;

      case 'MLV':
        locale.locale = 'es-VE';
        locale.currency = 'VES';
        break;

      case 'MLU':
        locale.locale = 'es-UY';
        locale.currency = 'UYU';
        break;

      default:
        break;
    }
  }

  return locale;
};

module.exports = { localeFromSiteId };
