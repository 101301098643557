const React = require('react');
const PropTypes = require('prop-types');
const { injectI18n } = require('nordic/i18n');

// Andes components
const { Text, Typography } = require('@andes/typography');

// Utils
const translate = require('../../../../../translation');

const LegalsLink = ({ translations, url }) => (
  <Typography size="xs" component="a" color="link" href={url} target="_blank">
    {translations.LEGALS_LINK}
  </Typography>
);

const Legals = ({ i18n, siteId }) => {
  const translations = translate(i18n);

  const getLegalsText = () => {
    switch (siteId) {
      case 'MLB':
        return translations.CONSUMER_CREDITS_LEGALS_MLB();

      case 'MLM':
        return translations.CONSUMER_CREDITS_LEGALS_MLM({
          components: {
            0: <LegalsLink translations={translations} url="https://www.mercadopago.com.mx/ayuda/4768" />,
          },
        });

      default:
        return translations.CONSUMER_CREDITS_LEGALS_MLA();
    }
  };

  return (
    <Text size="xs" color="secondary">
      {getLegalsText()}
    </Text>
  );
};

Legals.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
};

module.exports = injectI18n(Legals);
