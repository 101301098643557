import type { TypographyProps } from '@andes/typography';
import type {
  PaymentSummaryItem,
  PaymentSummarySplitPayment,
  PaymentSummaryTotal,
} from '../../@types/summary';
import type { MessageProps } from '@andes/message';

import React, { useState } from 'react';

// Andes Components
import { Card, CardContent } from '@andes/card';
import { Message } from '@andes/message';
import { Typography } from '@andes/typography';

// Custom Components
import RowItem from '../RowItem';
import Overlay from '../Overlay';

// Icons
import Chevron, { ChevronShapeEnum } from '../Icons/Chevron';

// Constants
import { Colors } from '../../constants/styles';

export type PaymentSummaryMobileProps = {
  title: string;
  // Items Row
  items?: Array<PaymentSummaryItem>;
  splitPayment?: PaymentSummarySplitPayment;
  // Total Row
  total?: PaymentSummaryTotal & {
    nameObservation?: string;
    nameObservationColor?: TypographyProps['color'];
    nameObservationSize?: Exclude<TypographyProps['size'], 'xl'>;
    priceObservation?: string;
    priceObservationColor?: TypographyProps['color'];
    priceObservationSize?: Exclude<TypographyProps['size'], 'xl'>;
    helperText?: string;
  };
  aspirational?: {
    status: MessageProps['color'];
    title: string;
    label: string;
  };
  brand?: boolean;
  isCollapsible?: boolean;
  isUserLogged?: boolean;
  withNavbar?: boolean;
  autoHeightClassname?: string;
};

function PaymentSummaryMobile({
  title,
  items,
  total,
  splitPayment,
  aspirational,
  brand = false,
  isUserLogged = false,
  isCollapsible = false,
  withNavbar = false,
  autoHeightClassname = '',
}: PaymentSummaryMobileProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [iconRotation, setRotateIcon] = useState(false);
  const [discountItem, setDiscountItem] = useState<
    PaymentSummaryItem | undefined
  >(undefined);

  const isExpandedClass = isExpanded ? 'expanded' : 'closed';

  const isLogged = isUserLogged ? 'user-logged' : 'user-guest';

  const hasBrand = brand ? `${isLogged}--brand` : isLogged;

  const hasNavbar = !withNavbar ? 'not-navbar' : '';

  const getDiscountItem = () => {
    if (items !== null && Array.isArray(items)) {
      setDiscountItem(items.find((item) => item.type === 'coupon_discount'));
    }
  };

  const toggleExpand = () => {
    setRotateIcon(!iconRotation);
    setIsExpanded(!isExpanded);
  };

  React.useEffect(() => {
    getDiscountItem();
  }, [items]);

  return (
    <>
      {isCollapsible ? (
        <>
          <Card
            className={`cow-payment_summary_mobile cow-payment_summary_mobile--collapsible ${isExpandedClass} ${hasBrand} ${hasNavbar}`}
            paddingSize={0}
          >
            <CardContent className="collapsible-content">
              <div className={`collapsible-content__detail ${isExpandedClass}`}>
                <Typography type="body" className="title">
                  {title}
                </Typography>

                <div className="collapsible-content__detail--items">
                  {items?.map((item, index) => (
                    <RowItem
                      className="row-item"
                      key={index}
                      label={item.name}
                      value={item.price}
                      labelClassName="item-label"
                      valueClassName="item-value"
                      labelColor={item.nameColor || 'primary'}
                      valueColor={item.priceColor || 'primary'}
                      labelComplement={item.nameComplement}
                      labelComplementColor={item.nameComplementColor}
                      labelComplementClassName="item-label-complement"
                      valueCents={item.priceCents}
                      valueCentsType={item.priceCentsType}
                      valueSymbol={item.priceSymbol}
                      valueWeight={item.priceWeight}
                      valueSize={item.priceSize}
                      icon={item.icon}
                    />
                  ))}
                </div>

                {splitPayment && (
                  <div className="split-payment">
                    <Typography type="body" size="s" className="title">
                      {splitPayment.label}
                    </Typography>
                    {splitPayment.methods.map((method, index) => (
                      <RowItem
                        key={index}
                        label={method.name}
                        value={method.price}
                        labelClassName="item-label"
                        valueClassName="item-value"
                        className="row-item"
                      />
                    ))}
                  </div>
                )}
              </div>

              <div className={`total-value--container ${isExpandedClass}`}>
                {discountItem && (
                  <RowItem
                    className="total-value--container--item"
                    label={discountItem.name}
                    value={discountItem.price}
                    labelClassName="item-label"
                    valueClassName="item-value"
                    labelColor={discountItem.nameColor || 'positive'}
                    valueColor={discountItem.priceColor || 'primary'}
                    labelComplement={discountItem.nameComplement}
                    labelComplementClassName="item-label-complement"
                  />
                )}
                {total && (
                  <RowItem
                    className="total-value--container--total"
                    label={total.name}
                    value={total.price}
                    valueCents={total?.priceCents}
                    valueCentsType={total?.priceCentsType}
                    labelWeight="semibold"
                    valueWeight="semibold"
                    labelClassName="item"
                    valueClassName="item"
                    valueSize={16}
                  />
                )}
              </div>
            </CardContent>
            <div className="chevron-button" onClick={toggleExpand}>
              <Chevron
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill={Colors.COW_LIGHT_BLUE}
                color={Colors.COW_LIGHT_BLUE}
                chevronShape={ChevronShapeEnum.DEFAULT}
                opacity="1"
                className={`chevron-up ${iconRotation ? 'chevron-down' : ''}`}
              />
            </div>
          </Card>
          {isExpanded && <Overlay onClick={toggleExpand} />}
        </>
      ) : (
        <Card
          className={`cow-payment_summary_mobile cow-payment_summary_mobile--static ${hasBrand} ${autoHeightClassname} ${hasNavbar}`}
          paddingSize={0}
        >
          <CardContent>
            {aspirational && (
              <div className="aspirational-message">
                <Message
                  color={aspirational.status}
                  hierarchy={'quiet'}
                  title={<span>{aspirational.title}</span>}
                >
                  {aspirational.label}
                </Message>
              </div>
            )}

            {items && items.length > 0 && (
              <div className="items--container">
                {items.map(
                  ({
                    // Name
                    name,
                    nameColor = 'primary',
                    nameWeight,
                    nameComplement,
                    nameComplementColor,
                    nameObservation,
                    icon,
                    iconAlignment,
                    // Price
                    price,
                    priceColor = 'primary',
                    priceWeight,
                    priceSize,
                    priceCents,
                    priceCentsType,
                    priceSymbol,
                    priceObservation,
                    // General
                    type,
                    color,
                  }) => (
                    <RowItem
                      key={`${name}-${price}`}
                      label={name}
                      labelColor={color || nameColor}
                      value={price}
                      valueWeight={priceWeight}
                      valueSize={priceSize}
                      valueCents={priceCents}
                      valueCentsType={priceCentsType}
                      valueSymbol={priceSymbol}
                      valueColor={color || priceColor}
                      labelWeight={nameWeight}
                      className={`cow-payment_summary_mobile__row--${
                        type || 'product'
                      }`}
                      icon={icon}
                      iconAlignment={iconAlignment}
                      labelComplement={nameComplement}
                      labelComplementColor={nameComplementColor}
                      labelObservation={nameObservation}
                      valueObservation={priceObservation}
                    />
                  ),
                )}
              </div>
            )}

            <div
              className={`total-value--container ${items ? 'with-items' : ''}`}
            >
              {total && (
                <RowItem
                  label={total.name}
                  labelWeight={total.nameWeight || 'semibold'}
                  value={total.price}
                  valueWeight={total.priceWeight || 'semibold'}
                  valueObservation={total.priceObservation}
                  valueObservationColor={
                    total.priceObservationColor || 'positive'
                  }
                  valueCents={total?.priceCents}
                  valueCentsType={total?.priceCentsType}
                  labelClassName="item-label"
                  valueSymbol={total.priceSymbol}
                  valueSize={total.priceSize}
                  labelObservation={total.nameObservation}
                  labelObservationColor={total.nameObservationColor}
                  labelObservationTextSize={total.nameObservationSize}
                />
              )}
            </div>
          </CardContent>
        </Card>
      )}
    </>
  );
}

export default PaymentSummaryMobile;
