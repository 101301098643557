const React = require('react');
const PropTypes = require('prop-types');
const { injectI18n } = require('nordic/i18n');

// Andes components
const { Text } = require('@andes/typography');

// Utils
const translate = require('../../../../../translation');
const { formatNextDueDate } = require('../../../../../utils/Date');
const { localeFromSiteId } = require('../../../../../utils/locale');

const HighlightedDate = ({ date, locale }) => (
  <Text size="s" color="secondary" weight="semibold">
    {formatNextDueDate(date, locale)}
  </Text>
);

const NextDueDate = ({ date, siteId, i18n }) => {
  const translations = translate(i18n);
  const { locale } = localeFromSiteId(siteId);

  return (
    date && (
      <Text size="s" color="secondary">
        {translations.CONSUMER_CREDITS_NEXT_DUE_DATE({
          components: {
            0: <HighlightedDate date={date} locale={locale} />,
          },
        })}
      </Text>
    )
  );
};

NextDueDate.propTypes = {
  date: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
};

module.exports = injectI18n(NextDueDate);
