/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const {
  EXPRESS_RESET_CARD,
  EXPRESS_VALIDATE_CARD,
  EXPRESS_RESET_INSTALLMENTS,
  EXPRESS_UPDATE_INSTALLMENTS,
  EXPRESS_SET_INSTALLMENTS_ERROR,
  EXPRESS_SET_SECURE_FIELDS_FOCUS,
  EXPRESS_SET_SECURE_FIELDS_ERROR,
  EXPRESS_RESET_SECURE_FIELDS,
} = require('../actions/types');

const actions = {};

const initialState = {
  installments: {},
  secureField: {
    error: '',
    focus: false,
  },
};

actions[EXPRESS_VALIDATE_CARD] = (state, action) => ({
  ...state,
  ...action.payload,
});

actions[EXPRESS_RESET_CARD] = (state, action) => ({
  ...state,
  ...action.payload,
});

actions[EXPRESS_UPDATE_INSTALLMENTS] = (state, action) => ({
  ...state,
  installments: {
    ...state.installments,
    [action.payload.paymentMethod]: {
      ...action.payload.installments[action.payload.paymentMethod],
    },
  },
});

actions[EXPRESS_RESET_INSTALLMENTS] = (state, action) => ({
  ...state,
  installments: {
    ...state.installments,
    [action.payload.paymentMethod]: {
      ...action.payload.installments[action.payload.paymentMethod],
    },
  },
});

actions[EXPRESS_SET_INSTALLMENTS_ERROR] = (state, action) => ({
  ...state,
  installments: {
    ...state.installments,
    [action.payload.paymentMethod]: {
      ...state.installments[action.payload.paymentMethod],
      error: action.payload.installments[action.payload.paymentMethod].error,
    },
  },
});

actions[EXPRESS_SET_SECURE_FIELDS_FOCUS] = (state, action) => ({
  ...state,
  secureField: {
    ...state.secureField,
    focus: action.payload.secureField.focus,
  },
});

actions[EXPRESS_SET_SECURE_FIELDS_ERROR] = (state, action) => ({
  ...state,
  secureField: {
    ...state.secureField,
    error: action.payload.secureField.error,
  },
});

actions[EXPRESS_RESET_SECURE_FIELDS] = (state, action) => ({
  ...state,
  secureField: {
    error: '',
    focus: false,
  },
});

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
