const React = require('react');
const PropTypes = require('prop-types');

const Legals = require('./Legals');
const NextDueDate = require('./NextDueDate');
const TermsAndConditions = require('./TermsAndConditions');

const Container = ({ children, className }) => <div className={className}>{children}</div>;

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const ConsumerCredits = Container;
ConsumerCredits.Legals = Legals;
ConsumerCredits.NextDueDate = NextDueDate;
ConsumerCredits.TermsAndConditions = TermsAndConditions;

module.exports = ConsumerCredits;
