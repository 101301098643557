import React from 'react';
import { Modal } from '@andes/modal';
import { DeviceType } from '../../constants/commons';

export type GenericModalProps = {
  title: string;
  content: React.ReactNode;
  deviceType?: DeviceType.DESKTOP | DeviceType.MOBILE;
  isOpen?: boolean;
  showClose?: boolean;
  className?: string;
  maxWidth?: number;
  onClose?: () => any;
  onOpen?: () => any;
};

const GenericModal = ({
  deviceType = DeviceType.DESKTOP,
  className = '',
  title,
  content,
  isOpen = false,
  showClose = true,
  maxWidth = 500,
  onClose,
  onOpen
}: GenericModalProps) => {
  return (
    <React.Fragment>
      <Modal
        title={title}
        className={`generic-modal ${className}`}
        closable={showClose}
        open={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        type={deviceType === DeviceType.MOBILE ? 'full' : 'large'}
        maxWidth={maxWidth}
      >
        <div className="generic-modal__content">
          {content}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default GenericModal;
