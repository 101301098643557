import React, { useMemo } from 'react';
import { Image } from 'nordic/image';

import { Thumbnail } from '@andes/thumbnail';
import { getInitials } from '../../utils/string';

export type BrandProps = {
  name: string;
  image?: string;
  imageTitle?: string;
  imageAltText?: string;
  maxNameLength?: number;
  maxInitialsLength?: number;
  shape?: 'circle' | 'square';
  isMobile?: boolean;
  isUserLogged?: boolean;
  withNavbar?: boolean;
};

export default function Brand({
  name,
  image,
  imageTitle = 'Brand logo',
  imageAltText = 'Brand logo',
  maxNameLength = 17,
  maxInitialsLength = 2,
  shape = 'circle',
  isMobile = false,
  isUserLogged = false,
  withNavbar = false,
}: BrandProps) {
  const nameSize = useMemo(
    () => (name?.length >= maxNameLength ? 'large' : 'small'),
    [name, maxNameLength],
  );

  const isLogged = isUserLogged ? 'user-logged' : 'user-guest';
  const hasNavbar = !withNavbar ? 'not-navbar' : '';

  return (
    <div
      className={`brand ${
        isMobile ? 'brand--mobile' : ''
      } ${isLogged} ${hasNavbar}`}
    >
      <Thumbnail className="brand__thumbnail" modifier={shape}>
        {image ? (
          <Image
            className="brand__thumbnail--image"
            alt={imageAltText}
            src={image}
            title={imageTitle}
            lazyload="off"
          />
        ) : (
          <span className="brand__thumbnail--text">
            {getInitials(name, maxInitialsLength)}
          </span>
        )}
      </Thumbnail>

      <span className={`brand__name brand__name--${nameSize}`}>{name}</span>
    </div>
  );
}
