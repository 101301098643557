const React = require('react');
/**
 * Icon needed to simulate @andes/textfield behaviour
 * @TODO remove this when secure fields have a full support for andes components / or secure field as react library
 * @returns
 */
const ErrorIcon = () => (
  <svg aria-hidden="true" width="12" height="12" viewBox="0 0 12 12" fill="white">
    <path d="M6.72725 2.90906H5.27271L5.45452 6.90906H6.54543L6.72725 2.90906Z" fill="white"></path>
    <path
      d="M5.99998 7.63633C6.40164 7.63633 6.72725 7.96194 6.72725 8.3636C6.72725 8.76527 6.40164 9.09088 5.99998 9.09088C5.59832 9.09088 5.27271 8.76527 5.27271 8.3636C5.27271 7.96194 5.59832 7.63633 5.99998 7.63633Z"
      fill="white"
    ></path>
  </svg>
);

module.exports = React.memo(ErrorIcon);
