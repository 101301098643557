import type { PillSpecificProps } from '@andes/badge';
import type { RadioListItemProps } from '@andes/radio-list';

import React from 'react';
import classNames from 'classnames';

import { Card } from '@andes/card';
import { Pill } from '@andes/badge';
import { Text } from '@andes/typography';
import { Accordion } from '@andes/accordion';
import { RadioList, RadioListItem } from '@andes/radio-list';

import PaymentMethodLogo from '../PaymentMethodLogo';

import { getTitle } from '../../utils/getTitle';
import { DeviceType } from '../../constants/commons';

const { DESKTOP, MOBILE } = DeviceType;

type Item = {
  id: string;
  title: string;
  description: string;
  withPromotion?: boolean;
  additionalInfo?: string;
  collapsedContent?: React.ReactNode;
  highlightBadge?: HighlightBadgeProps;
  paymentMethodId: string;
  fullTitle?: string;
};

export type HighlightBadgeProps = {
  text?: string;
} & PillSpecificProps;

export type PaymentMethodCardListProps = {
  items: Item[];
  deviceType?: DeviceType;
  withDividers?: boolean;
  withContentDividers?: boolean;
  currentSelected?: string;
  bottomContentList?: React.ReactNode;
  onChange: (id: string) => void;
};

const CustomRadioListItem = (
  props: RadioListItemProps & {
    expanded: boolean;
    deviceType: DeviceType;
    collapsedContent: React.ReactNode;
  },
) => (
  <>
    <RadioListItem
      className={`radio-list-item-${props.deviceType}`}
      {...props}
    />
    {props.collapsedContent && (
      <Accordion expanded={props.expanded} extend title={null}>
        <div className="payment-method-card-list__options__content">
          {props.collapsedContent}
        </div>
      </Accordion>
    )}
  </>
);

CustomRadioListItem.wraps = RadioListItem;

const HighlightBadge = (props: HighlightBadgeProps) => (
  <div className="payment-method-card-list__highlight">
    <Pill {...props}>{props.text}</Pill>
  </div>
);

export default function PaymentMethodCardList({
  items,
  onChange,
  withDividers,
  currentSelected,
  bottomContentList,
  withContentDividers,
  deviceType = DESKTOP,
}: PaymentMethodCardListProps) {
  return (
    <Card
      className={classNames('payment-method-card-list', {
        'payment-method-card-list--single': items.length === 1,
        'payment-method-card-list__divider': withContentDividers,
      })}
    >
      <RadioList
        srLabel="payment-method"
        value={currentSelected}
        withDividers={withDividers}
        defaultValue={currentSelected}
        onChange={(_event, item) => onChange(item.toString())}
      >
        {items.map((method) => (
          <CustomRadioListItem
            chevron={false}
            value={method.id}
            deviceType={deviceType}
            expanded={method.id === currentSelected}
            title={
              <div className="payment-method-card-list__title">
                {method.highlightBadge && deviceType === MOBILE && (
                  <HighlightBadge {...method.highlightBadge} />
                )}
                {getTitle(method.title, method.fullTitle)}
              </div>
            }
            collapsedContent={method.collapsedContent}
            leftContent={<PaymentMethodLogo method={method.paymentMethodId} />}
            rightContent={
              method.highlightBadge &&
              deviceType === DESKTOP && (
                <HighlightBadge {...method.highlightBadge} />
              )
            }
            description={
              <div className="payment-method-card-list__method">
                <Text size="s" color="secondary">
                  {method.description}
                </Text>

                {method.additionalInfo && (
                  <Text
                    size="s"
                    color={method.withPromotion ? 'positive' : 'secondary'}
                  >
                    {method.additionalInfo}
                  </Text>
                )}
              </div>
            }
          />
        ))}
      </RadioList>

      {bottomContentList}
    </Card>
  );
}
