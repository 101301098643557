import type { DropdownFormProps } from '@andes/dropdown';

import React, { useRef, useCallback } from 'react';
import { Dropdown, DropdownItem } from '@andes/dropdown';

type Item = {
  title: string;
  value: string;
  description?: string;
  highlighted?: boolean;
  disabled?: boolean;
};

export type SelectInstallmentsProps = {
  items: Array<Item>;
  label: string;
  value?: string;
  helper?: string;
  placeholder: string;
  defaultValue?: string;
  modifier?: DropdownFormProps['modifier'];
  menuAlignment?: DropdownFormProps['menuAlignment'];
  onChange: (
    event: React.KeyboardEvent | React.MouseEvent,
    value?: Array<string> | string | undefined,
  ) => void;
  renderCustomTrigger?: (
    actualContent: React.ReactNode,
    currentValues: Array<string> | string,
  ) => React.ReactNode;
};

function SelectInstallments({
  items,
  label,
  value,
  helper,
  modifier,
  placeholder,
  defaultValue,
  menuAlignment,
  renderCustomTrigger,
  onChange,
}: SelectInstallmentsProps) {
  // init workaround to fix the overflow issue when the dropdown is open
  const innerElementReference = useRef<HTMLDivElement | null>(null);

  const onOpenDropdown = useCallback(
    (event: React.KeyboardEvent | React.MouseEvent) => {
      const innerElement: HTMLDivElement | null = (
        event.target as HTMLLIElement
      ).closest('.andes-accordion-panel-transition__inner');
      if (innerElement) {
        innerElement.style.overflow = 'visible';
        innerElementReference.current = innerElement; // TODO REMOVE AFTER ANDES FIX ACCORDION PROBLEM
      }
    },
    [],
  );

  const onCloseDropdown = useCallback(() => {
    if (innerElementReference.current) {
      innerElementReference.current.style.overflow = 'hidden'; // TODO REMOVE AFTER ANDES FIX ACCORDION PROBLEM
    }
  }, []);
  // Finish

  return (
    <Dropdown
      id={`installments-${label}`}
      type="form"
      value={value}
      label={label}
      srLabel={label}
      helper={helper}
      modifier={modifier}
      menuMaxHeight={220}
      placeholder={placeholder}
      defaultValue={defaultValue}
      menuAlignment={menuAlignment}
      onOpen={onOpenDropdown}
      onClose={onCloseDropdown}
      renderCustomTrigger={renderCustomTrigger}
      onChange={onChange}
    >
      {items.map((item) => (
        <DropdownItem
          value={item.value}
          title={item.title}
          disabled={item.disabled}
          description={item.description}
          className={
            item.highlighted
              ? 'select-installments__description--highlighted'
              : ''
          }
        />
      ))}
    </Dropdown>
  );
}

export default React.memo(SelectInstallments);
