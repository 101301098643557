/* global MercadoPagoSDK */

const {
  APP: {
    SECURE_FIELDS: { EVENTS, FIELDS },
  },
  COMMONS: {
    PAYMENT_METHOD: { CREDIT_CARD, DEBIT_CARD },
    PLACEHOLDER: { CVV_THREE_DIGITS, CVV_FOUR_DIGITS },
  },
} = require('../../../../../../constants');

let secureFieldsInstance = null;

const mountSecureField = (paymentMethod, secureFieldActions) => {
  const mp = window.MercadoPagoSDK;

  const containerId = `${paymentMethod?.id}_${paymentMethod?.option_id}`;
  const container = document.getElementById(containerId);

  const shouldMount = paymentMethod.type === CREDIT_CARD || paymentMethod.type === DEBIT_CARD;

  if (!shouldMount || !container || secureFieldsInstance) {
    return;
  }

  secureFieldsInstance = mp.fields
    .create(FIELDS.SECURITY_CODE, {
      placeholder: paymentMethod.security_code.length === 4 ? CVV_FOUR_DIGITS : CVV_THREE_DIGITS,
      style: {
        fontWeight: '300',
        fontStyle: 'normal',
        fontFamily: '"Proxima Nova", -apple-system, Roboto, Arial, sans-serif',
        placeholderColor: 'rgba(0, 0, 0, 0.55)',
        color: 'rgba(0, 0, 0, 0.9)',
      },
    })
    .mount(containerId);

  secureFieldsInstance.on(EVENTS.READY, () => {
    secureFieldsInstance.update({
      settings: {
        mode: 'mandatory',
        length: paymentMethod.security_code.length,
      },
    });
  });

  secureFieldsInstance.on(EVENTS.FOCUS, () => {
    secureFieldActions?.setSecureFieldFocus(true);
    secureFieldActions?.setSecureFieldError(false);
  });

  secureFieldsInstance.on(EVENTS.BLUR, () => {
    secureFieldActions?.setSecureFieldFocus(false);
  });
};

const unmountSecureField = () => {
  if (secureFieldsInstance) {
    secureFieldsInstance.unmount();
    secureFieldsInstance = null;
  }
};

const remountSecureField = (paymentMethod, secureFieldActions) => {
  unmountSecureField();
  mountSecureField(paymentMethod, secureFieldActions);
  secureFieldActions?.resetSecureField();
};

const createCardToken = async () => {
  let error = null;
  let response = null;

  try {
    response = await window.MercadoPagoSDK.fields.createCardToken({});
  } catch (err) {
    error = err;
  }

  return { error, response };
};

const createCardTokenWithEsc = async (cardId, internalDeviceId) => {
  let error = null;
  let response = null;

  try {
    const payload = {
      requireEsc: true,
      fingerprint: {
        vendor_ids: [
          {
            name: 'browser',
            value: internalDeviceId,
          },
        ],
      },
    };

    response = await MercadoPagoSDK.fields.createCardToken({ cardId }, payload);
  } catch (err) {
    error = err;
  }

  return { error, response };
};

module.exports = {
  mountSecureField,
  unmountSecureField,
  remountSecureField,
  createCardTokenWithEsc,
  createCardToken,
};
