/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const { ESC_VALIDATE, ESC_RESET } = require('../actions/types');

const actions = {};

const initialState = {
  cardId: '',
  cardTokenId: undefined,
  cardIdsValidated: {},
};

actions[ESC_VALIDATE] = (state, action) => ({
  ...state,
  cardTokenId: action.payload.cardTokenId,
  cardId: action.payload.cardId,
  cardIdsValidated: {
    ...state.cardIdsValidated,
    [action.payload.cardId]: action.payload.cardTokenId ? 'VALID' : 'INVALID',
  },
});

actions[ESC_RESET] = (state) => ({
  ...state,
  cardTokenId: undefined,
  cardId: '',
  cardIdsValidated: {},
});

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
