const { getExternalWebviewType } = require('./externalWebview');

// Constants
const { WEBVIEW_TYPES, PAY_IN_APP } = require('../../constants/app');
const { DEVICE_TYPE } = require('../../constants/commons');

const getWebviewType = (params = {}, device = {}, navigator = {}, isDescriptionRequired = false) => {
  const searchParams = new URLSearchParams(params);
  const sniffingWebview = searchParams.get(PAY_IN_APP.PARAMS.SNIFFED) === 'true';
  const isDeviceWebview = device?.isWebview || device?.webView;

  if (isDeviceWebview && sniffingWebview) {
    return WEBVIEW_TYPES.SNIFFING;
  }

  if (isDeviceWebview) {
    return WEBVIEW_TYPES.INTERNAL;
  }

  return getExternalWebviewType(navigator, isDescriptionRequired);
};

const isSniffingWebview = (params, device, navigator) =>
  getWebviewType(params, device, navigator) === WEBVIEW_TYPES.SNIFFING;

const isInternalWebview = (params, device, navigator) =>
  getWebviewType(params, device, navigator) === WEBVIEW_TYPES.INTERNAL;

const isMobile = (deviceType) => deviceType === DEVICE_TYPE.MOBILE;

module.exports = { getWebviewType, isSniffingWebview, isInternalWebview, isMobile };
