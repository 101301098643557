const { createAction } = require('redux-actions');

const { DUPLICATED_PAYMENT_MODAL } = require('./types');

const showDuplicatedPaymentModal = createAction(DUPLICATED_PAYMENT_MODAL.SHOW);
const closeDuplicatedPaymentModal = createAction(DUPLICATED_PAYMENT_MODAL.CLOSE);
const allowDuplicatedPayment = createAction(DUPLICATED_PAYMENT_MODAL.ALLOW_DUPLICATED_PAYMENT);

module.exports = {
  showDuplicatedPaymentModal: ({ paidWithALoggedUser }) => (dispatch) =>
    dispatch(showDuplicatedPaymentModal({ paidWithALoggedUser })),
  closeDuplicatedPaymentModal: () => (dispatch) => dispatch(closeDuplicatedPaymentModal()),
  allowDuplicatedPayment: () => (dispatch) => dispatch(allowDuplicatedPayment()),
};
