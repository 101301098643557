/**
 * Module dependencies
 */
const { handleActions } = require('redux-actions');

const {
  EMPTY_PAGE,
  PAGE_LOAD,
} = require('../actions/types');
const { isNewInterface } = require('../../utils/HiddenComponents');

const actions = {};

const initialState = {
  data: {},
  isNewInterface: false,
  firstRender: true,
  flow: {
    id: '',
    type: '',
    step: '',
    action: '',
  },
};

actions[EMPTY_PAGE] = state => ({
  ...state,
  data: {},
});

actions[PAGE_LOAD] = (state, action) => {
  const newState = {
    ...state,
    data: {
      ...action.payload.data,
      checkout: state.data?.checkout,
    },
    firstRender: false,
    isNewInterface: isNewInterface(action.payload.data),
  };

  // Set the new step from the response
  newState.flow.step = newState.data.currentStep;
  newState.flow.id = newState.data.flowId;

  return newState;
};

/**
 * Expose reducer
 */
module.exports = handleActions(actions, initialState);
