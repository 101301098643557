/**
 * Module dependencies
 */
const { createAction } = require('redux-actions');

const {
  LOADER_TRIGGERED,
  LOADER_PAY_BUTTON,
  DISABLE_LOADING,
} = require('./types');

// Request Actions
const triggerLoader = createAction(LOADER_TRIGGERED);
const triggerLoaderPayButton = createAction(LOADER_PAY_BUTTON);
const disableLoadingAction = createAction(DISABLE_LOADING);

/**
 * Export functions
 */
module.exports = {
  [LOADER_TRIGGERED]: (type, id = null) => dispatch => dispatch(triggerLoader({ id, type })),
  [LOADER_PAY_BUTTON]: (isLoading) => dispatch => dispatch(triggerLoaderPayButton({ isLoading })),
  disableLoading: () => dispatch => dispatch(disableLoadingAction()),
};
