module.exports = {
  // COMMON APP
  DEVICE_OS_NAME: {
    ANDROID: 'android',
    IOS: 'ios',
  },
  THEME: {
    DEFAULT: 'default',
    ML: 'ml',
    MP: 'mp',
    POTS: 'pots',
    YELLOW_BUTTON: 'boton_amarillo',
  },
  BROWSER_NAME: {
    INTERNET_EXPLORER: 'ie',
    INTERNET_EXPLORER_MOBILE: 'ie mobile',
    MIUI_BROWSER: 'miui browser',
    FIREFOX: 'firefox',
    SAFARI: 'safari',
    MOBILE_SAFARI: 'mobile safari',
  },
  HTTP_STATUS: {
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    CONFLICT: 409,
    INTERNAL_SERVER_ERROR: 500,
    BAD_GATEWAY: 502,
  },
  SUBMIT_FORM_ID: {
    TWO_FACTOR_FORM: 'two_factor_form',
    KYC_FORM: 'kyc_form',
    LOGIN_FORM: 'login_form',
    LOGIN_REMEDY_FORM: 'login_remedy_form',
    FACETEC_FORM: 'facetec_form',
    THREEDS_FORM: 'threeds_form', // 3DS Challenge form ID
    UNICO_FORM: 'unico_form',
    RANDOM_CHARGE_FORM: 'random_charge_form',
    FIRST_AMOUNT_FORM: 'first_amount_form',
  },
  CHO_VERSION_PREFIX: {
    V2: 'v2',
  },
  CAPTCHA_VERSION: {
    V2: 'captcha_v2',
    V3: 'captcha_v3',
  },
  ENCODING_TYPES: {
    UTF8: 'utf8',
    HEX: 'hex',
    BINARY: 'binary',
  },
  URLS: {
    DEMO_COW: 'http://demo.cow.adminml.com',
    MERCADO_LIBRE: '*.mercadolibre.com',
    DEFAULT_MP_IMAGE: 'https://mercadolibre.com/org-img/mkt/email-mkt-assets/checkout-off/default-mp.png',
    DEFAULT_MP_SMALL_IMAGE: 'https://mercadolibre.com/org-img/mkt/email-mkt-assets/checkout-off/default-small-mp.png',
    MP_API_V1: 'https://api.mercadopago.com/v1',
    MP_MLDP: 'https://mldp.mercadopago.com',
    BASE_URL_MERCADO_LIBRE: 'https://www.mercadolibre.com',
    LOCAL_HOST: '127.0.0.1',
    CONSUMER_CREDITS_TYC: 'https://www.mercadolibre.com.ar/credits/consumer/',
    CONSUMER_CREDITS_TYC_BR: 'https://www.mercadolivre.com.br/credits/consumer/',
  },
  QUERY_PARAMS: {
    PREFERENCE_ID: 'preference-id',
    PAYMENT_ID: 'payment-id',
    TRANSACTION_INTENT_ID: 'transaction-intent-id',
    SETUP_INTENT_ID: 'setup-intent-id',
    CUSTOMER_ID: 'customer-id',
    PAYER_ID: 'payer-id',
    CARDS_IDS: 'card-ids',
    FROM_WIDGET: 'from-widget',
    DEVICE_OVERRIDE: 'device-override',
    ROUTER_REQUEST_ID: 'router-request-id',
    SNIFFING_FORCE_APP: 'sniffing-force-app',
    SNIFFING_FORCE_STRATEGY: 'sniffing-force-strategy',
    ACCESS_TOKEN: 'access_token',
    ACCESSTOKEN: 'accessToken',
    NAVIGATE_TO_APP: 'navigate-to-app',
    INTERNAL_CONFIGURATIONS: 'internal-configurations',
    PROFILE_HASH: 'p',
    JOURNEY_ID: 'journey-id',
  },
  QUERY_PARAMS_VALUE: {
    PX_FLOW_ID_LOGGED: '/checkout_web/logged',
    PX_FLOW_ID_WALLET_PURCHASE: '/checkout_web/wallet_purchase',
  },
  PARAMS_VALUE: {
    FLOW_ID: 'bb6e4699-52a6-4af7-8be7-a96959c29327',
  },
  CURRENCY_SYMBOL: {
    ARS: '$',
    BRL: 'R$',
    UYU: '$',
    MXN: '$',
    COP: '$',
    CLP: '$',
    PEN: 'S/',
    VES: 'Bs.',
  },
  MAPPED: {
    // Cross Checkouts specific params
    SUMMARY: 'summary',
    SUMMARY_TITLE: 'summary.title',
    SUMMARY_TOTAL_LABEL: 'summary.total.label',
    INTERNAL: 'internal',
    ACTION: 'action',
    HEADER_COLOR: 'header_color',
    ELEMENTS_COLOR: 'elements_color',
    BUTTON_CONFIRM_LABEL: 'button_confirm_label',
    FROM_WIDGET: 'from_widget',
    APP_CANDIDATE: 'app_candidate',
    // Tokenize Checkout specific params
    PUBLIC_KEY: 'public_key',
    TRANSACTION_AMOUNT: 'transaction_amount',
    CUSTOMER_ID: 'customer_id',
    DIFFERENTIAL_PRICING_ID: 'differential_pricing_id',
    PAYER_ID: 'payer_id',
    CARDS_IDS: 'cards_ids',
    EXCLUDED_PAYMENT_METHODS: 'excluded_payment_methods',
    EXCLUDED_PAYMENT_TYPES: 'excluded_payment_types',
    DEFAULT_CARD_ID: 'default_card_id',
    EXPRESS_FLOW: 'express_flow',
    PROCESSING_MODES: 'processing_modes',
    MIN_INSTALLMENTS: 'min_installments',
    MAX_INSTALLMENTS: 'max_installments',
    TRIAL_PAYMENT: 'trial_payment',
    ALTERNATIVE_PAYMENT: 'alternative_payment',
    SUMMARY_PRODUCT_AMOUNT: 'summary.product.amount',
    SUMMARY_PRODUCT_LABEL: 'summary.product.label',
    SUMMARY_DISCOUNT_AMOUNT: 'summary.discount.amount',
    SUMMARY_DISCOUNT_LABEL: 'summary.discount.label',
    SUMMARY_CHARGE_AMOUNT: 'summary.charge.amount',
    SUMMARY_TAXES_AMOUNT: 'summary.taxes.amount',
    SUMMARY_ARREARS_AMOUNT: 'summary.arrears.amount',
    SUMMARY_SHIPPING_AMOUNT: 'summary.shipping.amount',
    // Payment Checkout specific params
    PREFERENCE_ID: 'preference_id',
    PAYMENT_ID: 'payment_id',
    HASH: 'hash',
    COLLAPSIBLE_LAYOUT: 'collapsible_layout',
    SOURCE: 'source',
    // Get config specific params
    FLOW_CHECKOUT_BASE_PATH: 'services.flow.checkout.base_path',
  },
  CALLBACK_URL_LOADED: 'CALLBACK_URL_LOADED',
  REAUTH: {
    SETTINGS: {
      OPERATION_ID: 'mp-cow',
      OPERATION_ID_VERIFY: 'mp-cow-verify',
      FLOW_TYPE: 'payment',
    },
    ERROR: {
      UNAUTHORIZED: 401,
    },
  },
  // SERVER
  TYPE_HIDDEN: 'hidden',
  QUERY_COMPONENT_STATUS: '/:status?',
  REQ_QUERY_CRAWLER_DATA: 'crawler-data',
  BUNDLE: {
    EXPIRED: 'expired',
    EXCEPTION: 'exception',
    AUTH_CALLBACK: 'auth-callback',
    SNIFFING: 'sniffing',
    BROWSER_SUPPORT: 'browser-support',
    COMPATIBLE_PX: 'compatible-px',
    CRAWLER: 'crawler',
    ACCESS_DENIED: 'access-denied',
    PAYMENT_UNAVAILABLE: 'payment-unavailable',
    BLOCKED_POPUPS: 'blocked-popups',
    RESTART_FLOW: 'restart-flow',
    SKELETON: 'skeleton',
    WEBPAY_ONE_CLICK: 'webpay-one-click',
    OPENFINANCE_CALLBACK: 'openfinance-callback',
    OPENSEA_CREDITS: 'opensea-credits',
    REMEDY_RANDOM_CHARGE: 'remedy-random-charge',
    CHALLENGE_RANDOM_CHARGE_CALLBACK: 'challenge-random-charge-callback',
    LOGIN_CHALLENGE_CALLBACK: 'login-challenge-callback',
    UNICO_CHALLENGE_CALLBACK: 'unico-challenge-callback',
    WEBVIEW_SUPPORT: 'webview-support',
    TERMS_AND_CONDITIONS: 'terms-and-conditions',
    CHALLENGE_FACETEC_CALLBACK: 'challenge-facetec-callback',
  },
  TRACKING_DEFAULT_VALUE: {
    NOT_APPLY: 'not_apply',
  },
  TRACKING_PATH: {
    CHECKOUT_EXPIRED: '/CHECKOUT/EXPIRED/',
    CHECKOUT_ERROR: '/CHECKOUT/ERROR/',
    CHECKOUT_LOGIN: '/CHECKOUT/LOGIN/',
    CHECKOUT_SNIFFING: '/CHECKOUT/SNIFFING/',
    CHECKOUT_BROWSER_NOT_SUPPORTED: '/CHECKOUT/BROWSER_NOT_SUPPORTED/',
    CHECKOUT_ACCESS_DENIED: '/CHECKOUT/ACCESS_DENIED/',
    CHECKOUT_PAYMENT_UNAVAILABLE: '/CHECKOUT/PAYMENT_UNAVAILABLE/',
    CHECKOUT_BLOCKED_POPUPS: '/CHECKOUT/BLOCKED_POPUPS/',
    CHECKOUT_RESTART_FLOW: '/CHECKOUT/RESTART_FLOW/',
    MELIDATA_CHECKOUT_OFF_LOADING: '/checkout_off/loading',
    MELIDATA_CHECKOUT_OFF_SNIFFING: '/checkout_off/sniffing',
    MELIDATA_CHECKOUT_OFF_PX_COMPATIBILITY: '/checkout_off/loading/px_redirection',
    MELIDATA_CHECKOUT_OFF_REMEDY_3DS_CALLBACK: '/checkout_off/remedy/callback/3ds',
    MELIDATA_CHECKOUT_OFF_REMEDY_LOGIN_CALLBACK: '/checkout_off/remedy/callback/login',
    MELIDATA_CHECKOUT_OFF_REMEDY_RANDOM_CHARGE_CALLBACK: '/checkout_off/remedy/callback/random_charge',
    MELIDATA_CHECKOUT_OFF_REMEDY_FACETEC_CALLBACK: '/checkout_off/remedy/callback/facetec',
    MELIDATA_CHECKOUT_OFF_REMEDY_UNICO_CALLBACK: '/checkout_off/remedy/callback/unico',
    LOADING: '/LOADING/',
    GA_ONE_CLICK_PROCESSING: '/CHECKOUT/PAYMENT/ONE_CLICK_PROCESSING/',
    MELIDATA_ONE_CLICK_PROCESSING: '/checkout_off/payment/one_click_processing',
    GA_ONE_CLICK_RETURN: '/CHECKOUT/PAYMENT/ONE_CLICK_RETURN/',
    MELIDATA_ONE_CLICK_RETURN: '/checkout_off/payment/one_click_return',
    MELIDATA_CHECKOUT_OFF_ERROR: '/checkout_off/error',
  },
  CURRENT_STEP: {
    EXCEPTION: 'exception',
    EXPIRED: 'expired',
    AUTH_CALLBACK: 'auth_callback',
    SNIFFING: 'sniffing',
    BROWSER_SUPPORT: 'browser_support',
    COMPATIBLE_PX: 'compatible-px',
    CRAWLERS: 'crawlers',
    ACCESS_DENIED: 'access_denied',
    PAYMENT_UNAVAILABLE: 'payment_unavailable',
    BLOCKED_POPUPS: 'blocked_popups',
    RESTART_FLOW: 'restart_flow',
    SKELETON: 'skeleton',
    LOGIN: 'login',
    LOADING: 'loading',
    REDIRECT: 'redirect',
    CHALLENGE: 'challenge',
    CHALLENGE_REAUTH: 'reauth',
    RANDOM_CHARGE_CALLBACK: 'random_charge_callback',
    UNICO_CALLBACK: 'unico_callback',
    WEBVIEW_SUPPORT: 'webview_support',
    FACETEC_CALLBACK: 'challenge_facetec_callback',
    WEBPAY_ONE_CLICK: 'webpay_one_click',
    CONGRATS_REDIRECT: 'congrats_redirect',
    FIRST_SPLIT_PAYMENT: 'first_split_payment',
    SECOND_SPLIT_PAYMENT: 'second_split_payment',
    PAYMENT_OPTION_FORM: 'payment_option_form',
    PAYMENT_OPTION_FORM_COMBINATION: 'payment_option_form_combination',
    CONSUMER_CREDITS: 'consumer_credits',
    IDENTIFICATION_FORM: 'identification_form',
    TERMS_AND_CONDITIONS: 'terms_and_conditions',
    CARD_FORM: 'card_form',
  },
  CHECKOUT_PURPOSE: {
    WALLET_PURCHASE: 'wallet_purchase',
  },
  CHECKOUT_PRODUCT: {
    LINK: 'link',
    WALLET: 'wallet',
    SUBSCRIPTION: 'subscription',
  },
  // STEP MAPS
  URL: {
    CARD_FORM: 'card-form',
    CARD_FORM_FULL: 'card-form-full',
    IDENTIFICATION: 'identification',
    EXTRA_DATA: 'extra-data',
    ISSUER: 'issuer',
    INSTALLMENTS: 'installments',
    SECURITY_CODE: 'security-code',
    REVIEW_SPLIT: 'review/split',
    REVIEW: 'review',
    YAPE: 'yape',
    EXPRESS: 'express',
    CHECKOUT_EXPRESS_OFF_PAYMENT: 'checkout_express_off_payment',
    CONSUMER_CREDITS: 'consumer-credits',
    PROCESSING: 'processing',
    CHALLENGE: 'challenge',
    LOGIN: 'login',
    LOGIN_CHALLENGE_REMEDY: 'login-challenge-remedy',
    LOGIN_REMEDY_CVV: 'login-remedy-cvv',
    LOGIN_INCENTIVE: 'login-incentive',
    ERROR: 'error',
    FATAL: 'fatal',
    REDIRECT: 'redirect',
    PAYMENT_OPTION_FORM: 'payment-option-form',
    PAYMENT_OPTION_FORM_V2: 'payment-option-form-v2',
    PAYMENT_OPTION_FORM_COMBINATION: 'payment_option_form_combination',
    PAYMENT_OPTION_FORM_LOGIN_A: 'payment-option-form-login-a',
    PAYMENT_OPTION_FORM_LOGIN_B: 'payment-option-form-login-b',
    PAYMENT_OPTION_FORM_LOGIN_C: 'payment-option-form-login-c',
    PAYMENT_OPTION_FORM_LOGIN_D: 'payment-option-form-login-d',
    PAYMENT_OPTION_FORM_LOGIN_EMAIL: 'payment-option-form-login-email',
    MP_PAYMENT_OPTION_FORM: 'mercadopago/payment-option-form',
    MP_NO_PAYMENT_OPTION_FORM: 'mercadopago/no-payment-option-form',
    PAYMENT_UNAVAILABLE: 'payment-unavailable',
    CONGRATS_REJECTED: 'congrats/rejected',
    CONGRATS_APPROVED: 'congrats/approved',
    CONGRATS_INSTRUCTIONS: 'congrats/instructions',
    CONGRATS_RECOVER_CALL_FOR_AUTH: 'congrats/recover/call-for-auth',
    CONGRATS_RECOVER_CALL_FOR_AUTH_AUTHORIZE: 'congrats/recover/call-for-auth/authorize',
    CONGRATS_RECOVER_CALL_FOR_AUTH_LATER: 'congrats/recover/call-for-auth/later',
    CONGRATS_RECOVER_ERROR: 'congrats/recover/error',
    CONGRATS_RECOVER_TIMEOUT: 'congrats/recover/timeout',
    CONGRATS_RECOVER_DEFERRED_RETRY: 'congrats/recover/deferred-retry',
    CONGRATS_RECOVER_EXCHANGE_RATE_INVALID: 'congrats/recover/exchange-rate-invalid',
    CONGRATS_RECOVER_EXPRESS: 'congrats/recover/express',
    CONGRATS_RECOVER_EXPRESS_ACCOUNT_MONEY: 'congrats/recover/express/account-money',
    CONGRATS_RECOVER_EXPRESS_CONSUMER_CREDITS: 'congrats/recover/express/consumer-credits',
    WALLET_CARD_FORM: 'wallet/card-form',
    CONGRATS_RECOVER_CARD_DISABLED: 'congrats/recover/card-disabled',
    WALLET_CONGRATS_APPROVED: 'wallet/congrats/approved',
    WALLET_CONGRATS_REJECTED: 'wallet/congrats/rejected',
    CONGRATS_RECOVER_BAD_CARD_NUMBER: 'congrats/recover/bad-card-number',
    CONGRATS_RECOVER_BAD_OTHER: 'congrats/recover/bad-other',
    CONGRATS_RECOVER_BAD_DATE: 'congrats/recover/bad-date',
    CONGRATS_RECOVER_BAD_SECURITY_CODE: 'congrats/recover/bad-security-code',
    CONGRATS_RECOVER_INVALID_INSTALLMENTS: 'congrats/recover/invalid-installments',
    CONGRATS_RECOVER_INSUFFICIENT_AMOUNT: 'congrats/recover/insufficient-amount',
    LOGIN_WALLET: 'login/wallet',
    CONGRATS_REMEDY: 'congrats/remedy',
    CONGRATS_RECOVER_CONTINGENCY: 'congrats/recover/contingency',
    CONGRATS_RECOVER_REVIEW_MANUAL: 'congrats/recover/review-manual',
    CONGRATS_RECOVER_REMEDY_OFFER: 'congrats/recover/remedy-offer',
    ACCOUNT_MONEY_SECOND_FACTOR: 'account-money/second-factor',
    FIRST_SPLIT_PAYMENT: 'first-split-payment',
    SECOND_SPLIT_PAYMENT: 'second-split-payment',
    WALLET: 'wallet',
    SHIPPING_OPTIONS: 'shipping/options',
    SHIPPING_REVIEW: 'shipping/review',
    CONGRATS_REDIRECT: 'congrats/redirect',
    ONECLICK_REDIRECT: 'oneclick/redirect',
    SHIPPING_ZIPCODE_FORM: 'shipping/zipcode-form',
    SHIPPING_ADDRESS_FORM: 'shipping/address-form',
    SHIPPING_USER_TYPE: 'shipping/user-type',
    SHIPPING_TYPE: 'shipping/type',
    SHIPPING_ADDRESS_SELECTOR: 'shipping/address-selector',
    CHALLENGE_3DS: 'challenge/3ds',
    CHALLENGE_RANDOM_CHARGE_REMEDY: 'challenge-random-charge-remedy',
    AGENCIES_MAP: 'agencies-map',
    CONSUMER_CREDITS_TYC: 'consumer-credits-tyc',
    OPENSEA_CREDITS_FIRST_USE: 'opensea-credits-first-use',
    OPENSEA_CREDITS_REDIRECT: 'opensea-credits-redirect',
    OPENSEA_CREDITS_RETRY: 'opensea-credits-retry',
    TRACKING_REDIRECT: 'tracking/redirect',
    REVERSIBLE_ERROR: 'reversible-error',
    SNIFFING_SCAN_QR: 'sniffing-scan-qr',
    OPENFINANCE_REDIRECT: 'openfinance-redirect',
    OPENFINANCE_AWAITING: 'openfinance-awaiting',
    OPENFINANCE_PENDING: 'openfinance-pending',
    OPENFINANCE_BANK_SELECTION: 'open-finance-bank-selection',
    CHALLENGE_3DS_REMEDY: 'challenge-3ds-remedy',
    UNICO_CHALLENGE_REMEDY: 'unico-challenge-remedy',
    NOT_FOUND_ERROR: 'not-found-error',
    WEBVIEW_NOT_SUPPORTED: 'webview-support',
    CHALLENGE_FACETEC_REMEDY: 'challenge-facetec-remedy',
    COMBINED_PAYMENT_AMOUNT_FORM: 'combined-payment-amount-form',
    COMBINED_PAYMENT_REVIEW: 'combined-payment-review',
    PERSONAL_INFO_FORM_STEP: 'personal-info-form',
    ADDRESS_INFO_FORM_STEP: 'address-info-form',
    TERMS_AND_CONDITIONS: 'terms-and-conditions',
  },
  PAGE_ACTIONS: {
    PAY: 'pay',
    REVIEW_EXPRESS_PAY: 'review_express_pay',
    PAY_WITH_CONSUMER_CREDITS: 'use_consumer_credits',
    DISMISS_CONSUMER_CREDITS: 'dismiss_consumer_credits',
    CHANGE_PAYMENT_OPTION: 'change_payment_form',
    CHANGE_INSTALLMENTS: 'change_installments',
    CHANGE_EXTRA_DATA: 'change_extra_data',
    CHANGE_EMAIL: 'change_email',
    SEND_DISCOUNT_CODE: 'group_discount',
    CHANGE_SHIPPING_OPTION: 'change_shipping_option_form',
    CHANGE_SHIPPING_ADDRESS: 'change_address_form',
    NEXT_STEP: 'next_step',
    INPUT_CARD: 'input_card_form',
    SELECT_INSTALLMENTS: 'select_installments',
    ERROR: 'error',
  },
  ERROR_FIELDS: {
    REQUIRED: 'required',
    INVALID_EMAIL: 'invalid_email',
    INVALID_DISCOUNT_CODE: 'invalid_discount_code',
  },
  SUMMARY_ITEMS_TYPES: {
    PRODUCT: 'product',
    COUPON_DISCOUNT: 'coupon_discount',
    PREF_DISCOUNT: 'pref_discount',
    SHIPPING: 'shipping',
    TAX: 'tax',
    SUBTOTAL: 'subtotal',
  },
  CHALLENGES: {
    KYC: 'kyc',
    CHALLENGE_COMPONENT_TYPE: 'group_challenge',
  },
  NOTIFICATIONS_STATUS: {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
  },
  PAGE_NAME: {
    CARD_FORM: 'card-form',
    CARD_FORM_FULL: 'card-form-full',
    INSTALLMENTS_FORM: 'installments-form',
    IDENTIFICATION_FORM: 'identification-form',
    GENERIC: 'generic',
    REVIEW: 'review',
    YAPE: 'yape',
    SECURITY_CODE_FORM: 'security-code-form',
    EXPRESS: 'express',
    EXCEPTION: 'exception',
    CONSUMER_CREDITS: 'consumer-credits',
    PROCESSING: 'processing',
    CHALLENGE: 'challenge',
    LOGIN: 'login',
    LOGIN_CHALLENGE_REMEDY: 'login-challenge-remedy',
    LOGIN_REMEDY_CVV: 'login-remedy-cvv',
    LOGIN_INCENTIVE: 'login-incentive',
    CONGRATS: 'congrats',
    CONGRATS_RECOVER_FORM: 'congrats-recover-form',
    WALLET_CARD_FORM: 'wallet-card-form',
    WALLET_CONGRATS_APPROVED: 'wallet-congrats-approved',
    WALLET_CONGRATS_REJECTED: 'wallet-congrats-rejected',
    WALLET_LOGIN: 'wallet-login',
    SPLIT_PAYMENT: 'split-payment',
    WALLET_PAYMENT: 'wallet-payment',
    SPINNER: 'spinner',
    TRACKING_REDIRECT: 'tracking-redirect',
    OPENFINANCE_REDIRECT: 'openfinance-redirect',
    CHALLENGE_3DS_REMEDY: 'challenge-3ds-remedy',
    UNICO_CHALLENGE_REMEDY: 'unico-challenge-remedy',
    CHALLENGE_RANDOM_CHARGE_REMEDY: 'challenge-random-charge-remedy',
    NOT_FOUND: 'not-found',
    WEBVIEW_NOT_SUPPORTED: 'webview-support',
    CHALLENGE_FACETEC_REMEDY: 'challenge-facetec-remedy',
    PAYMENT_OPTION_FORM: 'payment-option-form',
    PAYMENT_OPTION_FORM_COMBINATION: 'payment-option-combination',
    PAYMENT_OPTION_FORM_V2: 'payment-option-form-v2',
    COMBINED_PAYMENT_AMOUNT_FORM: 'combined-payment-amount-form',
    ADDRESS_INFO_FORM_STEP: 'address-info-form',
    PERSONAL_INFO_FORM_STEP: 'personal-info-form-step',
    TERMS_AND_CONDITIONS: 'terms-and-conditions',
  },
  STEPS: {
    CARD_FORM: 'card_form',
  },
  CHUNK: {
    CARD_FORM: 'card_form',
    CARD_FORM_FULL: 'card_form_full',
    INSTALLMENTS_FORM: 'installments_form',
    IDENTIFICATION_FORM: 'identification_form',
    GENERIC: 'generic',
    REVIEW: 'review',
    YAPE: 'yape',
    SECURITY_CODE_FORM: 'security_code_form',
    EXPRESS: 'express',
    EXCEPTION: 'exception',
    CREDITS: 'credits',
    PROCESSING: 'processing',
    CHALLENGE: 'challenge',
    LOGIN: 'login',
    LOGIN_CHALLENGE_REMEDY: 'login-challenge-remedy',
    LOGIN_REMEDY_CVV: 'login-remedy-cvv',
    LOGIN_INCENTIVE: 'login-incentive',
    CONGRATS: 'congrats',
    CONGRATS_REVOVER_FORM: 'congrats_recover_form',
    WALLET_CARD_FORM: 'wallet_card_form',
    WALLET_CONGRATS_APPROVED: 'wallet-congrats-approved',
    WALLET_CONGRATS_REJECTED: 'wallet-congrats-rejected',
    WALLET_LOGIN: 'wallet_login',
    SPLIT_PAYMENT: 'split_payment',
    WALLET_PAYMENT: 'wallet_payment',
    SPINNER: 'spinner',
    TRACKING_REDIRECT: 'tracking_redirect',
    OPENFINANCE: 'open-finance',
    CHALLENGE_3DS_REMEDY: 'challenge-3ds-remedy',
    UNICO_CHALLENGE_REMEDY: 'unico-challenge-remedy',
    CHALLENGE_RANDOM_CHARGE_REMEDY: 'challenge-random-charge-remedy',
    NOT_FOUND_ERROR: 'not_found_error',
    WEBVIEW_NOT_SUPPORTED: 'webview-support',
    CHALLENGE_FACETEC_REMEDY: 'challenge-facetec-remedy',
    COMBINED_PAYMENT_AMOUNT_FORM: 'combined-payment-amount-form',
    PAYMENT_OPTION_FORM_COMBINATION: 'payment-option-combination',
    PAYMENT_OPTION_FORM: 'payment-option-form',
    PAYMENT_OPTION_FORM_V2: 'payment-option-form-v2',
    ADDRESS_INFO_FORM_STEP: 'address-info-form',
    PERSONAL_INFO_FORM_STEP: 'personal-info-form-step',
    TERMS_AND_CONDITIONS: 'terms-and-conditions',
  },
  // MIDDLEWARES
  // CHECK OPERATOR
  TELESALE_PRODUCT_ID: 'BDCA9T50FCR001F95280',
  CHECKOUT_INTERNAL_MP: 'in_mp',
  OPERATION_TYPE_POS_PAYMENT: 'pos_payment',
  USER_SCOPES: {
    PAY_SEND_MONEY: 'pay_send_money',
    COLLECT_TELESALE: 'collect_telesales',
    FUND: 'fund',
  },
  // CHECK CONSTRUCTOR
  PARAM_OBJECT_NAME: {
    SUMMARY_TITLE: 'summary-title',
    SUMMARY_TOTAL_LABEL: 'summary-total-label',
    SUMMARY_PRODUCT: 'summary-product',
    SUMMARY_PRODUCT_LABEL: 'summary-product-label',
    SUMMARY_DISCOUNT: 'summary-discount',
    SUMMARY_DISCOUNT_LABEL: 'summary-discount-label',
    SUMMARY_CHARGE: 'summary-charge',
    SUMMARY_TAXES: 'summary-taxes',
    SUMMARY_ARREARS: 'summary-arrears',
    SUMMARY_SHIPPING: 'summary-shipping',
    INTERNAL_CONFIGURATIONS: 'internal-configurations',
    HEADER_COLOR: 'header-color',
    ELEMENTS_COLOR: 'elements-color',
    BUTTON_CONFIRM_LABEL: 'button-confirm-label',
    FROM_WIDGET: 'from-widget',
    APP_CANDIDATE: 'app-candidate',
    PUBLIC_KEY: 'public-key',
    TRANSACTION_AMOUNT: 'transaction-amount',
    CUSTOMER_ID: 'customer-id',
    DIFFERENTIAL_PRICING_ID: 'differential-pricing-id',
    PAYER_ID: 'payer-id',
    CARDS_IDS: 'card-ids',
    EXCLUDED_PAYMENT_METHODS: 'excluded-payment-methods',
    EXCLUDED_PAYMENT_TYPES: 'excluded-payment-types',
    DEFAULT_CARD_ID: 'default-card-id',
    EXPRESS_FLOW: 'express-flow',
    PROCESSING_MODES: 'processing-modes',
    MIN_INSTALLMENTS: 'min-installments',
    MAX_INSTALLMENTS: 'max-installments',
    TRIAL_PAYMENT: 'trial-payment',
    ALTERNATIVE_PAYMENT: 'alternative-payment',
    PREFERENCE_ID: 'preference-id',
    PAYMENT_ID: 'payment-id',
    COLLAPSIBLE_LAYOUT: 'collapsible-layout',
  },
  // COMPONENTS GROUP
  COMPONENTS_GROUP_NAME: {
    SUMMARY_PRODUCT: 'summary_product',
  },
  // EXPERIMENTS MIDDLEWARE
  EXPERIMENTS: {
    CHECKOUT_OFF_OPEN_FINANCE_NEW_PAYMENT_METHOD: {
      NAME: 'checkout-off/open_finance_new_payment_method',
    },
    CHECKOUT_OFF_UNIFY_CARD_FORM: {
      NAME: 'checkout_off/unify-card-form',
    },
    CHECKOUT_OFF_SCOPED_SESSION: {
      NAME: 'checkout_off/scoped_session',
    },
    CHECKOUT_OFF_REVIEW_EXPRESS_REDESIGNED: {
      NAME: 'checkout_off/review_express_redesigned_experiment',
    },
  },
  // NORMALIZE DATA
  COMPONENT_ID: {
    ONECLICK_USERNAME: 'oneclick_username',
    ONECLICK_TOKEN: 'oneclick_token',
    PREFERENCE_ID: 'preference_id',
    TRACKING: 'tracking',
    PAYMENT_METHOD: 'payment_methods',
    PROCESS_VERSION: 'process-version',
    BACK_URL: 'back_url',
    CHECKOUT_REFERER: 'checkout_referer_url',
    SECURE_FIELDS_ENABLED: 'secure_fields_enabled',
    ESC_ENABLED: 'esc_enabled',
    CARD_ID: 'card_id',
    INSTALLMENT_DYNAMIC: 'bind_input_select_installment_dynamic',
    SELECTED_PAYMENT_METHOD_ID: 'selected_payment_method',
    REDIRECT_TO: 'redirect_to',
    ZERO_DOLLAR_ERROR: 'zero_dollar_error',
    ERROR_CODE: 'error_code',
    COLLECTOR_ID: 'collector_id',
  },
  // UTILS
  // CIPHER
  ALGORITHM: {
    ADVANCED_ENCRYPTION_STANDARD: 'aes-256-cbc',
    MD5: 'md5',
  },
  // ENUM
  NATIVE_APP_NAME_MAP: {
    MercadoLibre: 'meli',
    MercadoPago: 'mercadopago',
  },
  NATIVE_WEBKIT_APP_NAME_MAP: {
    MercadoLibre: 'MercadoLibre',
    MercadoPago: 'MercadoPago',
  },
  PAYMENT_CARD: {
    BIN_LENGTH: 8,
  },
  SECURE_FIELDS: {
    ERROR_CAUSES: {
      INVALID_TYPE: 'invalid_type',
      INVALID_LENGTH: 'invalid_length',
      INVALID_VALUE: 'invalid_value',
      EMPTY_VALUE: 'empty_value',
      // this is a custom error not supported by the SDK
      INVALID_PAYMENT_METHOD: 'invalid_payment_method',
    },
    ERROR_MESSAGE: {
      INVALID_FIELD: 'Invalid Field - [%s]',
    },
    FIELDS: {
      CARD_NUMBER: 'cardNumber',
      SECURITY_CODE: 'securityCode',
      EXPIRATION_DATE: 'expirationDate',
      EXPIRATION_MONTH: 'expirationMonth',
      EXPIRATION_YEAR: 'expirationYear',
    },
    EVENTS: {
      BLUR: 'blur',
      FOCUS: 'focus',
      ERROR: 'error',
      READY: 'ready',
      CHANGE: 'change',
      BIN_CHANGE: 'binChange',
      VALIDITY_CHANGE: 'validityChange',
    },
  },
  // gateway error codes map
  GATEWAY_ERROR_MAP: {
    INVALID_CARDNUMBER: 'E202',
    INVALID_CARDNUMBER_LENGTH: 'E301',
    INVALID_LENGTH_CARDNUMBER: 'E201',
    INVALID_LENGTH_SECURITYCODE: 'E302',
    INVALID_CARDHOLDER_NAME: '316',
    INVALID_EXPIRATION_DATE: '301',
    INVALID_EXPIRATION_MONTH: '325',
    INVALID_CARDEXPIRATIONYEAR: '326',
    IVALID_EXPIRATION_YEAR: '702',
    INVALID_SECURITYCODE: 'E203',
    INVALID_ESC: 'E216',
  },
  CHALLENGE: {
    REAUTH: 'reauth',
  },
  REMEDIES_STATUS: {
    PARAMS: {
      REMEDIES_CHALLENCHE_STATUS: 'remedies.challenge-status',
    },
    CHALLENGE_STATUS: {
      COMPLETED: 'completed',
      ERROR: 'error',
      TIMEOUT: 'timeout',
      DISCARD: 'discard',
    },
  },
  ZERO_DOLLAR_STATUS: {
    REJECTED: 'rejected',
    HIGH_RISK: 'high_risk',
  },
  // log
  ERROR_MESSAGE: {
    NO_SPECIFIED_IN_ERROR: 'no specified in error',
    CROSS_FRAME_SCRIPT: 'Cross Frame Scripting',
  },
  ERROR_SPA: {
    CARD_FORM_BRICKS_ERROR: 'card_form_bricks_error',
    UNAUTHORIZED_ORIGIN_URL: 'unauthorized_origin URL',
    REACT_CRASH_ERROR: 'react_crash_error',
    CHALLENGE_3DS_ERROR: 'challenge_3ds_error',
    SECURE_FIELD_WRAPPER_ERROR: 'secure_field_wrapper_error',
    CARD_FORM_FULL_ERROR: 'card_form_full_error',
    SECURITY_CODE_FORM_ERROR: 'security_code_form_error',
    SPLIT_PAYMENT_ERROR: 'split_payment_error',
    SKELETON_TRACKING_ERROR: 'skeleton_tracking_error',
    SNIFFING_TRACKING_ERROR: 'sniffing_tracking_error',
    SNIFFING_SAFARI_ERROR: 'sniffing_safari_error',
    WALLET_CARD_FORM_ERROR: 'wallet_card_form_error',
    WALLET_PAYMENT_ERROR: 'wallet_payment_error',
    MOBILE_WEBKIT_UTIL_ERROR: 'mobile_webkit_util_error',
    CUSTOM_TOKENIZATION_ERROR: 'custom_tokenization_error',
    SDK_TOKENIZATION_ERROR: 'sdk_tokenization_error',
    SDK_ESC_TOKENIZATION_ERROR: 'sdk_esc_tokenization_error',
    ACTIONS_ESC_ERROR: 'actions_esc_error',
    ACTIONS_ESC_VALIDATOR: 'actions_esc_validator',
    SCRIPT_ERROR: 'Script error.',
    BROWSER_INFO_ERROR: 'browser_info_error',
    BRAND_INITIALS_ERROR: 'brand_initials_error',
    CHALLENGE_ERROR: 'challenge_error',
    SKELETON_HANDLE_ERROR: 'skeleton_handle_error',
    TRACKING_REDIRECT_NOT_A_CHALLENGE: 'tracking_redirect_not_a_challenge',
    WEBPAY_ONE_CLICK: 'webpay_one_click',
    CHALLENGE_CONFIG_RAW_DATA: 'challenge_config_raw_data',
    HEADERS_ADD_TRACKING_PRODUCT_ID: 'headers_add_tracking_product_id',
    CURRENCY_PARSE_AMOUNT: 'currency_parse_amount',
    CURRENCY_GET_FLOAT_VALUE: 'currency_get_float_value',
    INSTALLMENT_FORM_INPUT_ERROR: 'installment_form_input_error',
  },
  ERROR_IDENTIFIER: {
    NODE: 'ND',
    FRONTEND: 'FE',
    FLOWS: 'FL',
    NO_CONTROLLED: 'no_controlled',
    UNEXPECTED_ERROR: 'unexpected_error',
    NOT_APPLICABLE: 'N/A',
  },
  COOKIES: {
    ESC: '_mp_esc_',
    RECOVERY_ERROR_COUNTER: 'MP-CHO-OFF-RECOVERY-COUNTER',
  },
  USER_TYPES: {
    LOGGED: 'logged',
    GUEST: 'guest',
  },
  PAY_IN_APP: {
    APP_STRATEGY: {
      PX: 'px',
      WEBVIEW: 'webview',
    },
    ACTIVITY: {
      PAY_PREFERENCE: 'pay-preference',
      WEBVIEW: 'webview',
    },
    PARAMS: {
      SNIFFED: 'sniffed',
      APP: 'app',
    },
  },
  WEBVIEW_TYPES: {
    SNIFFING: 'sniffing',
    INTERNAL: 'internal',
    EXTERNAL: 'external',
  },
  PLATFORM: {
    ML: 'ml',
    MP: 'mp',
  },
  INTERNAL_CONFIGURATIONS: {
    YELLOW_BUTTON: 'boton_amarillo',
    IN_ML: 'in_ml',
    IN_MP: 'in_mp',
    IN_POTS: 'in_pots',
  },
  FEATURE_NAMES: {
    HAS_PAYMENT_NOTIFICATION: 'HAS_PAYMENT_NOTIFICATION',
    HAS_MARKETPLACE: 'HAS_MARKETPLACE',
    IS_MLM: 'IS_MLM',
    HAS_BACK_URL: 'HAS_BACK_URL',
  },
  TRACKS: {
    INIT_SESSION: 'init_session',
    CONTINUE_AS_GUEST: 'continue_as_guest',
    BOTTOM_SHEET_CLOSED: 'bottom_sheet_closed',
  },
  TRACK_EVENT: {
    ACTION: {
      CLICK_RETRY_EXCEPTION_BUTTON: 'CLICK_RETRY_EXCEPTION_BUTTON',
    },
    CATEGORY: {
      VISIBLE_COMPONENT: 'VISIBLE_COMPONENT',
    },
  },
  BOTTOM_SHEET: {
    ONCE_VIEWED: 'onceViewed',
  },
  CHECKOUT_TYPE: {
    REDIRECT: 'redirect',
  },
  INAPP: {
    VENDOR: {
      UNKNOWN: 'unknown',
    },
  },
  PRODUCTION_SCOPES: {
    SANDBOX: 'sandbox',
    SUBSCRIPTION_DEFAULT: 'subscription-default',
    CHECKOUT_MLM: 'checkout-mlm',
    CHECKOUT_MLA: 'checkout-mla',
    CHECKOUT_MLB: 'checkout-mlb',
    CHECKOUT_DEFAULT: 'checkout-default',
    TOKENIZER_DEFAULT: 'tokenizer-default',
  },
  DEV_SCOPES: {
    BETA: 'beta',
    DELTA: 'delta',
    ALPHA: 'alpha',
    SANDBOX: 'sandbox',
  },
  ID: {
    LOGIN_ML: 'login_ml',
  },
  SNIFFING_ATTEMPT_STATUS: {
    NO_SNIFFING_ATTEMPT: 'no_sniffing_attempt',
    FAILED: 'failed',
    SUCCESSFUL: 'successful',
  },
  WINDOW_EVENT_MESSAGE: {
    MP_CHO_FINISHED_PAYMENT: 'mp_cho_finished_payment',
  },
  CUSTOM_EVENTS: {
    ZERO_DOLLAR_VALIDATION: 'zero_dollar_validation',
    PAGE_RELOADED_BY_FLOWS: 'page_reloaded_by_flows',
  },
  PAYMENT_METHOD: {
    DEFERRED_CAPTURE: {
      SUPPORTED: 'supported',
    },
  },
  ICON: {
    INFO: 'info',
  },
  HIDDEN_COMPONENTS: {
    SHOW_COACH_MARK_INSUFFICIENT_AMOUNT: 'show_coach_marks_insufficient_amount',
    IS_SECOND_PAYMENT_METHOD_COMBINATION: 'is_second_payment_method_combination',
    SELECTED_PAYMENT_METHOD: 'selected_payment_method',
    CANDIDATE_JUROS_COMBINED_PAYMENT_REVIEW: 'candidate_juros_combined_payment_review',
    DUPLICATED_PAYMENT: 'duplicated_payment',
  },
  STICKY_SUMMARY: {
    ICON_QUESTION_MARK: 'icon_question_mark',
    SEPARATOR: 'separator',
    ROW_COLLAPSIBLE: 'row_collapsible',
    ICON_WITH_TOOLTIP: 'icon_with_tooltip',
    SUMMARY_LIST_COMBINATION: 'summary_list_combination',
  },
  SUMMARY_TYPES: {
    GROUP_SIDEBAR: 'group_sidebar',
    STICKY_SUMMARY: 'sticky_summary',
    STICKY_SUMMARY_PAY: 'sticky_summary_pay',
    STICKY_SUMMARY_PAY_COMBINED: 'sticky_summary_pay_combined',
    STICKY_SUMMARY_COUPON: 'sticky_summary_coupon',
    GROUP_NAVBAR_BRAND: 'group_navbar_brand',
  },
  NEW_INTERFACE: 'new-interface',
  YAPE_API_ERROR: {
    INVALID_OTP: 'invalid_otp',
    INVALID_OTP_VAULT: 'vault_save_security_code_error',
    INVALID_PHONE_NUMBER: 'invalid_phone_number',
    INVALID_PHONE_VAULT: 'vault_save_phone_error',
  },
  TYPE: {
    MANUAL: 'manual',
  },
  INPUT_MODIFIER: {
    ERROR: 'error',
    COMPLETED: 'completed',
  },
  DOCUMENTS: {
    CPF: 'CPF',
    CNPJ: 'CNPJ',
    CPF_PLACEHOLDER: '999.999.999-99',
    CNPJ_PLACEHOLDER: '99.999.999/9999-99',
    CPF_MASK_LENGTH: 14,
    CNPJ_MASK_LENGTH: 18,
  },
  IS_NEW_INTERFACE: 'new-interface',
  CARD_FORM_BRICKS_SCOPE: {
    DEV: 'dev',
    PROD: 'prod',
  },
  CARD_FORM_BRICKS_CONFIG: {
    FLOW_ID: 'checkout-pro-payment',
    CHECKOUT_ID: 'cow',
  },
  CARD_FORM_BRICKS_ERRORS: {
    EXCLUDED_PAYMENT_TYPE_ERROR: 'excluded_payment_type_error',
    EXCLUDED_PAYMENT_ID_ERROR: 'excluded_payment_id_error',
    PAYMENT_METHOD_NOT_FOUND: 'PAYMENT_METHOD_NOT_FOUND',
    SUBMIT_FORM_ERROR: 'submit_with_error',
    MISSING_CARD_DATA: 'missing_card_data',
  },
  ERROR_TYPES: {
    TECHNICAL_ERROR: 'technical_error',
  },
};
