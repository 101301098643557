const { HIDDEN_COMPONENTS } = require('../../constants/app');
const { DEVICE_TYPE } = require('../../constants/commons');

const allowDuplicatedPayment = (duplicatedPayment) => {
  const currentDate = new Date();
  const firstAppearanceDuplicatedModal = duplicatedPayment?.firstAppearance;
  const fiveMinutesInMilliseconds = 300_000;
  const passedFiveMinutes = firstAppearanceDuplicatedModal
    ? currentDate - firstAppearanceDuplicatedModal > fiveMinutesInMilliseconds
    : false;

  return duplicatedPayment?.allowDuplicatedPayment || passedFiveMinutes;
};

const payload = ({ currentState, payload }) => {
  const checkoutSessionId = currentState.page?.data?.checkout?.session?.session_id;
  if (checkoutSessionId) {
    payload.checkout_session_id = checkoutSessionId;
  }

  if (allowDuplicatedPayment(currentState.duplicatedPayment)) {
    payload.allow_duplicated_payment = true;
  }

  return payload;
};

const handleModalAppearance = ({ currentState, hiddenComponents, isSameStep, dispatch, showDuplicatedPaymentModal }) => {
  const isMobile = currentState.configurations.deviceType === DEVICE_TYPE.MOBILE;

  if (!isMobile) {
    return;
  }

  const duplicatedPayment = hiddenComponents?.find(
    (component) => component.id === HIDDEN_COMPONENTS.DUPLICATED_PAYMENT,
  );

  if (!duplicatedPayment) {
    return;
  }

  const shouldShowDuplicatedPaymentModal =
    allowDuplicatedPayment(currentState.duplicatedPayment) === false &&
    currentState.duplicatedPayment?.show === false &&
    isSameStep;

  if (!shouldShowDuplicatedPaymentModal) {
    return;
  }

  const paidWithALoggedUser = duplicatedPayment.value.value;
  dispatch(showDuplicatedPaymentModal({ paidWithALoggedUser }));
};

module.exports = {
  payload,
  handleModalAppearance,
};
