const { checkReauthentication } = require('../service/api');
const { SUBMIT_FORM_ID } = require('../../constants/app');

/**
 * ChallengeConfig function that builds login challenge configuration
 * @param challengeRawData - component sent by Flows, this may be used to get some extra data.
 * @param configuration - Frontend's environment configurations.
 * @param redirectUrl - desired url to use a return url from challenge.
 * @param components - step components.
 * @param hiddenComponents - step hidden_components.
 */
const twofactorConfig = (challengeRawData, configuration, redirectUrl, components, hiddenComponents) => {
  const payment = hiddenComponents.find(({ id }) => id === 'payment');
  const { flowId, meliSessionId } = challengeRawData;

  return checkReauthentication(redirectUrl, flowId, meliSessionId).then(({ reauthUrl }) => {
    // Updates the current page's URL with the given parameters (Mandatory data for the ReAuth requests) without reloading the page
    if (!reauthUrl) {
      const queryParams = new URLSearchParams(window.location.search);
      // Add Params
      
      queryParams.append('total_amount', payment.value.total_amount);
      queryParams.append('collector_id', payment.value.collector_id);
      
      const paymentsEncoded = encodeURIComponent(JSON.stringify(payment.value.payments));
      queryParams.append('payments', paymentsEncoded);
      // Update URL
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, '', newUrl);
    }
    return {
      challengeUrl: reauthUrl,
      submitFormId: SUBMIT_FORM_ID.TWO_FACTOR_FORM,
      popupSetup: {
        width: 500,
        height: 600,
        completeOnPostmessage: true,
      },
    };
  });
};

module.exports.twofactorConfig = twofactorConfig;
