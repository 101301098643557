const React = require('react');

// Custom Components
const SecurityCode = require('../components/SecurityCode');
const InstallmentsSelector = require('../components/InstallmentsSelector');

const shouldRenderSecurityCode = (pm) => !!pm?.security_code;

const shouldRenderInstallments = (pm) => pm?.installments?.length !== 0 && !!pm?.show_installments;

const shouldRenderCollapsedContent = (pm, express) => {
  const cardToken = express?.[pm?.option_id]?.cardTokenId;
  const renderInstallments = shouldRenderInstallments(pm);

  if (cardToken) {
    return renderInstallments;
  }

  return shouldRenderSecurityCode(pm) || renderInstallments;
};

const CollapsedContent = ({ paymentMethod, discount }) => (
  <div className="review-express__collapsed-content">
    {shouldRenderSecurityCode(paymentMethod) && (
      <SecurityCode
        optionId={paymentMethod?.option_id}
        securityCode={paymentMethod?.security_code}
        escAvailable={paymentMethod?.esc_available}
        id={`${paymentMethod?.id}_${paymentMethod?.option_id}`}
      />
    )}

    {shouldRenderInstallments(paymentMethod) && (
      <InstallmentsSelector
        discount={discount}
        paymentMethod={paymentMethod?.option_id}
        items={paymentMethod?.installments}
      />
    )}
  </div>
);

const buildPaymentCardList = ({ paymentMethods, translations, discount, express }) =>
  paymentMethods.map((paymentMethod) => {
    const highlightBadge = paymentMethod?.recommended && {
      size: 'small',
      text: translations.RECOMMENDED_PAYMENT_METHOD,
    };

    const collapsedContent = shouldRenderCollapsedContent(paymentMethod, express) && (
      <CollapsedContent paymentMethod={paymentMethod} discount={discount} />
    );

    return {
      id: paymentMethod?.option_id,
      title: paymentMethod?.title,
      description: paymentMethod?.detail,
      withPromotion: paymentMethod?.promotional,
      additionalInfo: paymentMethod?.additional_info,
      paymentMethodId: paymentMethod?.id,
      collapsedContent,
      highlightBadge,
    };
  });

const usePaymentCardList = ({ paymentMethods, translations, discount, express }) => {
  const [paymentCardList, setPaymentCardList] = React.useState(
    buildPaymentCardList({
      express,
      discount,
      translations,
      paymentMethods,
    }),
  );

  React.useEffect(() => {
    setPaymentCardList(
      buildPaymentCardList({
        express,
        discount,
        translations,
        paymentMethods,
      }),
    );
  }, [express, paymentMethods]);

  return {
    paymentCardList,
    shouldRenderInstallments,
    shouldRenderSecurityCode,
  };
};

module.exports = { usePaymentCardList };
